import { Box, Flex, Stack } from "@chakra-ui/react";

// import CreatePodForm from "../../components/CreatePodForm/CreatePodForm";
import CreatePodFormUser from "../../components/CreatePodForm/CreateFormPodUser";

const CreatePod = () => {
    return (
        <Box pl={{ base: 0, md: 0, lg: 24 }}>
            <Flex justify={"center"} pt={"20"} alignItems={"center"}>
                <Stack w={{ base: "80%", md: "720px" }} spacing={8}>
                    <CreatePodFormUser />
                </Stack>
            </Flex>
        </Box>
    );
};

export default CreatePod;
