export const LINKS = [
    {
        title: "FAQs",
        href: "https://docs.mydailypod.com/",
    },

    {
        title: "Terms & Conditions",
        href: "https://docs.mydailypod.com/terms-and-conditions/",
    },
    {
        title: "Privacy Statement",
        href: "https://docs.mydailypod.com/privacy-policy",
    },
    {
        title: "Data Deletion Process",
        href: "https://docs.mydailypod.com/data-deletion-process",
    },
];
