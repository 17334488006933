import { Box, Flex, Stack, Text, Button, Spinner, SimpleGrid, VStack, useColorModeValue } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { createCheckoutSession } from "../../api/api";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteAccount } from "../../api/api";
import { useDispatch } from 'react-redux';
import { setUserProfile, setAllSubscriptions, setEpisodeCount } from '../../reducers/userReducer';

const ManageAccount = () => {
    const user = useSelector((state: RootState) => state.user.userProfile?.user);
    const currentPlan = user?.user_package_config?.name || "ALPHA"; // Default to ALPHA if not set
    console.log(currentPlan);
    const [loading, setLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const subscriptionTypes = [
        {
            name: "ALPHA",
            features: [
                "GPT 4.0 Mini",
                "Up to 3 YouTube Channels",
                "Free"
            ],
            isCurrentPlan: currentPlan === "ALPHA",
            productId: "price_1PQUvWCxArS9IlR41ke8703J" 
        },
        {
            name: "PREMIUM",
            features: [
                "GPT 4.0",
                "Unlimited YouTube Channels",
                "$0.99/month"
            ],
            isCurrentPlan: currentPlan === "PREMIUM",
            productId: "price_1PsOPtCxArS9IlR4uJbSkryT" 
        }
    ];

    const handleCheckoutSession = async (productId: string) => {
        setCheckoutLoading(true);
        try {
            const url = await createCheckoutSession(user?.email ?? '', productId);
            window.location.href = url;
        } catch (error) {
            console.error("Error creating checkout session", error);
            setCheckoutLoading(false);
        }
    };

    const handleDeleteAccount = async () => {
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            setDeleteLoading(true);
            try {
                await deleteAccount(user?._id ?? '');
                dispatch(setUserProfile(undefined));
                dispatch(setAllSubscriptions([]));
                dispatch(setEpisodeCount(undefined));
                localStorage.removeItem("isFirstLogin");
                navigate('/');
            } catch (error) {
                console.error("Error deleting account", error);
                setDeleteLoading(false);
            }
        }
    };

    const highlightColor = useColorModeValue("lightPrimary.50", "darkPrimary.700");
    const buttonColorScheme = useColorModeValue("blue", "darkPrimary");

    if (!user) {
        return (
            <Flex justify={"center"} alignItems={"center"} h={"100vh"}>
                <Spinner size="xl" />
            </Flex>
        );
    }

    return (
        <Box pl={{ base: 0, md: 0, lg: 24 }}>
            <Flex justify="center" pt="20" alignItems="center">
                <Box
                    w={{ base: "90%", md: "800px" }}
                    bg="white"
                    boxShadow="md"
                    borderRadius="lg"
                    p={6}
                >
                    <Text fontSize="2xl" fontWeight="bold" mb={6}>
                        Manage Account
                    </Text>

                    {/* Manage Subscription Section */}
                    <Box mb={8}>
                        <Text fontSize="xl" fontWeight="semibold" mb={4}>
                            Manage Subscription
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                            {subscriptionTypes.map((sub) => (
                                <Box
                                    key={sub.name}
                                    borderWidth={1}
                                    borderRadius="lg"
                                    p={4}
                                    bg={sub.isCurrentPlan ? highlightColor : "white"}
                                >
                                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                                        {sub.name}
                                    </Text>
                                    <VStack align="start" spacing={2} mb={4}>
                                        {sub.features.map((feature, index) => (
                                            <Text key={index}>{feature}</Text>
                                        ))}
                                    </VStack>
                                    <Button
                                        colorScheme={buttonColorScheme}
                                        onClick={() => handleCheckoutSession(sub.productId)}
                                        isLoading={checkoutLoading}
                                        isDisabled={sub.isCurrentPlan}
                                        size="md"
                                        width="full"
                                    >
                                        {sub.isCurrentPlan ? "Current Plan" : sub.name === "ALPHA" ? "Free Plan" : "Upgrade"}
                                    </Button>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </Box>

                    {/* Delete Account Section */}
                    <Box borderTop="1px" borderColor="gray.200" pt={6}>
                        <Text fontSize="xl" fontWeight="semibold" mb={4} color="red.500">
                            Delete Account
                        </Text>
                        <Text fontSize="sm" mb={4}>
                            Deleting your account is permanent and cannot be undone.
                        </Text>
                        <Button
                            colorScheme="red"
                            onClick={handleDeleteAccount}
                            isLoading={deleteLoading}
                            size="lg"
                            width="full"
                        >
                            Delete Account
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default ManageAccount;
