import { HStack, Heading, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const LogoSvg = () => {
    const logoSrc = useColorModeValue("/logo-black.svg", "/logo-white.svg");
    return (
        <img
            src={logoSrc}
            alt="Logo"
            style={{
                height: "30px",
                width: "30px",
                display: "inline-block",
            }}
        />
    );
};

type Props = {
    showAsSmall?: boolean;
};

const Logo = ({ showAsSmall }: Props) => {
    const textColor = useColorModeValue("black", "white");

    return (
        <Link to="/">
            <HStack cursor={"pointer"}>
                <LogoSvg />
                <Heading
                    fontWeight={600}
                    fontSize={{ base: "xl", sm: "xl", md: showAsSmall ? "xl" : "30px" }}
                    lineHeight={"110%"}
                    color={textColor}
                >
                    my daily pod
                </Heading>
            </HStack>
        </Link>
    );
};

export default Logo;
