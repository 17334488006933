// SubscriptionsView.tsx
import { Stack, Text, List, Link, ListItem, ListIcon, useColorModeValue } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import Badge from "./Badge";
import { Link as ReachLink } from "react-router-dom";

type SubscriptionsViewProps = {
    firstName?: string;
    subscriptionCount: number;
    episodeCount: number;
};

const SubscriptionsView = ({ firstName, subscriptionCount, episodeCount }: SubscriptionsViewProps) => {
    const textColor = useColorModeValue("black", "white");
    const listIconColor = useColorModeValue("green.500", "green.300");
    const linkColor = useColorModeValue("blue.500", "blue.300");

    return (
        <Stack w={{ base: "80%", md: "720px" }} spacing={8}>
            <Text fontSize="4xl" fontWeight="bold" color={textColor}>
                {firstName ? `Welcome back, ${firstName}!` : "Welcome!"}
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                <Badge count={subscriptionCount} text="Subscribed Channels" />
                <Badge count={episodeCount} text="Podcasts Created" />
            </Stack>
            <Text fontSize="xl" color={textColor}>
                {`While you're here, you can:`}
            </Text>
            <List spacing={3} fontSize="xl" color={textColor}>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />

                    <Link as={ReachLink} to="/user-preferences" color={linkColor} textDecoration="underline">
                        Add more YouTube channels
                    </Link>
                    {` to your Podcast feed.`}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`Create an `}
                    <Link as={ReachLink} to="/create-pod" color={linkColor} textDecoration="underline">
                        On Demand Podcast.
                    </Link>
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />

                    <Link as={ReachLink} to="/listen-to-episodes" color={linkColor} textDecoration="underline">
                        Listen
                    </Link>
                    {` to your Podcast feed.`}
                </ListItem>
            </List>
            <Text fontSize="xl" color={textColor}>

            {`Need more help?  Check out a 1-minute `}
                    <Link color={"blue.400"} href={"https://www.youtube.com/watch?v=5zOQMoIbof8"} isExternal>
                                        {` demo, `}
                                    </Link>{' '}
                                    read our
                    <Link color={"blue.400"} href={"https://docs.mydailypod.com"} isExternal> docs,</Link>
                    {` or Contact Us!`} 
            </Text>
        </Stack>
    );
};

export default SubscriptionsView;
