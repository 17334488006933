import { ReactNode, useState } from "react";
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    useColorMode,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Text,
    background,
    Divider,
    HStack,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { SunIcon, MoonIcon } from "@chakra-ui/icons";
import { IconType } from "react-icons";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setAllSubscriptions, setEpisodeCount, setUserProfile } from "../../reducers/userReducer";
import { CogIcon, PlusCircleIcon, MusicalNoteIcon } from "@heroicons/react/24/outline";
import {
    MusicalNoteIcon as MusicalNoteIconSolid,
    PlusCircleIcon as PlusCircleIconSolid,
    CogIcon as CogIconSolid,
} from "@heroicons/react/24/solid";
import { Link as ReachLink, useLocation, useNavigate } from "react-router-dom";
import LoginPage from "../../components/Login";
import Logo from "../../components/Logo/Logo";
import { IoIosLogOut } from "react-icons/io";
import { LINKS } from "../home/links";
import { getUserProfile } from "../../reducers/userReducer";
import ContactUs from "./ContactUs";

interface LinkItemProps {
    name: string;
    icon: IconType | any;
    href: string;
}
const LinkItems: Array<LinkItemProps> = [
    {
        name: "Create Pod: On Demand",
        icon: { outline: PlusCircleIcon, filled: PlusCircleIconSolid },
        href: "/create-pod",
    },
    {
        name: "Configure Podcast Feed",
        icon: { outline: CogIcon, filled: CogIconSolid },
        href: "/user-preferences",
    },
    {
        name: "Listen to Episodes",
        icon: { outline: MusicalNoteIcon, filled: MusicalNoteIconSolid },
        href: "/listen-to-episodes",
    },
];

interface MainLayoutProps {
    children: ReactNode;
}

export const ColorModeSwitcher = () => {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <IconButton
            aria-label="Toggle color mode"
            icon={colorMode === "light" ? <MoonIcon color={"black"} /> : <SunIcon />}
            onClick={toggleColorMode}
            variant="ghost"
            size="lg"
        />
    );
};

export default function MainLayout({ children }: MainLayoutProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box minH="100vh" bg={"primary.50"}>
            <SidebarContent onClose={() => onClose} display={{ base: "none", lg: "flex" }} />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size={{ base: "full", lg: "none" }}
            >
                <DrawerContent
                    onClick={onClose}
                    sx={{
                        "background-color": "rgb(236,225,225, 0.1)",
                        "-webkit-backdrop-filter": " blur(5px)",
                        "backdrop-filter": "blur(5px)",
                    }}
                    display={{ lg: "none" }}
                >
                    <SidebarContent onClick={(e) => e.stopPropagation()} onClose={onClose} />
                </DrawerContent>
            </Drawer>

            <MobileNav onOpen={onOpen} />
            <Box ml={{ base: 0, lg: 70 }} pt={0} pb={0} pr={0} minH={{ base: "80vh", lg: "100vh" }} maxH={"80vh"}>
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const dispatch = useAppDispatch();
    const sidebarBg = useColorModeValue("lightPrimary.100", "darkPrimary.100");
    const borderColor = useColorModeValue("lightPrimary.800", "darkPrimary.800");
    const linkColor = useColorModeValue("lightPrimary.400", "darkPrimary.400");
    const buttonColor = useColorModeValue("lightPrimary.400", "darkPrimary.400");

    const userProfile = useAppSelector(getUserProfile);

    const onClickLogout = () => {
        dispatch(setUserProfile(undefined));
        dispatch(setAllSubscriptions([]));
        dispatch(setEpisodeCount(undefined));
        localStorage.removeItem("isFirstLogin");
        window.location.reload();
    };

    return (
        <Flex
            {...rest}
            backgroundColor={sidebarBg}
            borderRight="0px"
            borderRadius={"lg"}
            boxShadow={"lg"}
            borderRightColor={borderColor}
            color={borderColor}
            pos="fixed"
            h={{ base: "full", lg: "full" }}
            sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                    display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
            }}
            overflowY={"auto"}
            zIndex="1"
            justify={{ base: "flex-start", md: "space-between" }}
            direction={"column"}
        >
            <Box>
                <Box marginBottom="10px" ml={{ base: -4, md: 0 }}>
                    <Flex h="20" alignItems="center" mx="6" justifyContent="space-between">
                        <Logo showAsSmall />
                        <CloseButton
                            bg="lightBackground"
                            p="2"
                            ml={10}
                            color="tertiary"
                            borderRadius={"sm"}
                            display={{ base: "flex", lg: "none" }}
                            onClick={onClose}
                        />
                    </Flex>
                </Box>
                <Flex
                    direction="column"
                    px={{ base: "4", lg: 0 }}
                    gap={{ base: 10, lg: 0 }}
                    justifyContent="space-between"
                    ml={{ base: -4, md: 0 }}
                >
                    <Box>
                        {LinkItems.map((link) => (
                            <NavItem key={link.name} icon={link.icon} href={link.href} onClose={onClose}>
                                {link.name}
                            </NavItem>
                        ))}
                    </Box>
                </Flex>
            </Box>

            <Box mx={{ base: 5, md: 10 }}>
                <Divider borderBottomWidth={"1px"} my={2} borderColor={"gray.400"} />
                <ContactUs />
            </Box>

            <Box mx={{ base: 5, md: 10 }} pb={10}>
                <Divider borderBottomWidth={"1px"} my={2} borderColor={"gray.400"} />
                {LINKS.map((link) => (
                    <Box key={link.title}>
                        <Link
                            href={link.href}
                            //textDecoration={"underline"}
                            fontSize={"sm"}
                            color={linkColor}
                        >
                            {link.title}
                        </Link>
                    </Box>
                ))}
                <Flex mt={2} align={"center"}>
                    <Menu placement="top">
                        <MenuButton as={Button} variant="ghost">
                            <Flex alignItems={"center"}>
                                <Avatar src={userProfile?.user?.user_info?.picture_url} size="xs" mr={2} />
                                <Text>{userProfile?.user?.email}</Text>
                            </Flex>
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onClickLogout}>
                                <IoIosLogOut />
                                <Text ml={2}>Logout</Text>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <ColorModeSwitcher />
                </Flex>
            </Box>
        </Flex>
    );
};

interface NavItemProps extends FlexProps {
    icon: { outline: IconType; filled: IconType };
    href: string;
    onClose: any;
    children: string;
}
const NavItem = ({ icon, href, onClose, children, ...rest }: NavItemProps) => {
    const location = useLocation();
    const isActive = location.pathname === href;
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const isFilled = isHovered || isActive;
    const IconComponent = isFilled ? icon.filled : icon.outline;
    const bg = useColorModeValue("#EEE", "gray.700");
    const color = useColorModeValue("lightPrimary.900", "darkPrimary.900");
    const textColor = useColorModeValue("black", "white");

    return (
        <Flex
            justify={"space-between"}
            align="center"
            mx="2"
            mt="2"
            p="2"
            bg={isFilled ? bg : "transparent"}
            color={isFilled ? color : textColor}
            borderRadius={"md"}
            role="group"
            cursor="pointer"
            {...rest}
            direction="row"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                navigate(href);
                onClose();
            }}
        >
            <Link as={ReachLink} to={href} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
                <Flex align="center" gap={3}>
                    <IconComponent className="group-hover:fill-current" width="24" height="24" />
                    {children}
                </Flex>
            </Link>
        </Flex>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    const bg = useColorModeValue("gray.100", "gray.900");
    const color = useColorModeValue("black", "white");

    return (
        <Flex
            px={{ base: 3, lg: 0 }}
            top={0}
            bottom={0}
            left={0}
            width={"100%"}
            overflowY={"auto"}
            zIndex={1}
            position={"sticky"}
            alignItems="center"
            bg={bg}
            justify={"space-between"}
            {...rest}
            h="16"
            display={{ base: "flex", lg: "none" }}
        >
            <IconButton
                border="0"
                onClick={onOpen}
                variant="elevated"
                color={color}
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <LoginPage />
        </Flex>
    );
};
