import { extendTheme, ThemeConfig, withDefaultColorScheme } from "@chakra-ui/react";
import colors from "./foundations/colors";
import { fonts, fontSizes, textStyles } from "./foundations/typography";
import sizes from "./foundations/sizes";
import semanticTokens from "./foundations/semantic-tokens";
import components from "./components";

const config: ThemeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
};

const theme = extendTheme(
    {
        config,
        colors,
        fonts,
        fontSizes,
        textStyles,
        sizes,
        semanticTokens,
        components,
    },
    withDefaultColorScheme({
        colorScheme: "lightPrimary",
    }),
);

export default theme;
