import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    Link,
    Stack,
    Text,
    useDisclosure,
    useColorModeValue,
    useToast,
    VStack,
    Stepper,
    Step,
    StepIndicator,
    StepSeparator,
    StepIcon,
    StepNumber,
    StepStatus,
    useSteps,
    Tag,
    Divider,
    Image,
} from "@chakra-ui/react";
import CreateSubscriptionForm from "../../components/CreateSubscriptionForm/CreateSubscriptionForm";
import React, { useEffect, useState } from "react";
import { deleteSubscription, fetchPodcastEpisodes, getSubscription } from "../../api";
import { useQuery } from "react-query";
import Loading from "../../components/Loading/Loading";
import { RiDeleteBin7Line } from "react-icons/ri";
import YoutubeLogo from "../../assets/youtube.png";
import AddCurrentSubscriptionModal from "../../components/AddCurrentSubscription/AddCurrentSubscription";
import AddPopularYouTubeChannelsModal from "../../components/AddPopularYouTubeChannels/AddPopularYouTubeChannels";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { setAllSubscriptions } from "../../reducers/userReducer";
import AllPodcastHost from "../../components/AllPodcastHost/AllPodcastHost";
import { formatDate, splitTitle } from "../../utils/helper";

const steps = [
    { name: "Step 1", title: "Select a popular channel", description: "to get a started quick, choose a popular" },
    { name: "Step 2", title: "Setup channels manually", description: "copy channel ULR from youtube" },
];

const UserPreferencesPage = () => {
    const { isOpen, onClose } = useDisclosure();
    const { isOpen: isOpenPopular, onOpen: onOpenPopular, onClose: onClosePopular } = useDisclosure();
    const email = useSelector((state: RootState) => state?.user?.userProfile?.user?.email || "");
    const allSubscriptions = useSelector((state: RootState) => state?.user?.allSubscriptions || []);
    const dispatch = useDispatch();
    const toast = useToast();
    const [prevSubscriptionCount, setPrevSubscriptionCount] = useState(allSubscriptions.length);
    const [isLoadingDeleteId, setIsLoadingDeleteId] = useState("");
    const [deleteApiError, setDeleteApiError] = useState("");
    const {
        data: subscriptions,
        error,
        isLoading,
        refetch,
    } = useQuery(["getSubscriptions", email], () => getSubscription(email), {
        enabled: false, // Disable automatic fetching on component mount
    });

    const {
        data: episodes,
        error: isErrorEpisode,
        isLoading: isLoadingEpisode,
        refetch: refetchEpisodes,
    } = useQuery(["podEpisodes", email], () => fetchPodcastEpisodes(email), {
        enabled: false, // Disable automatic fetching on component mount
    });

    useEffect(() => {
        if (email.length > 0) {
            refetch();
        }
    }, [email]);

    useEffect(() => {
        if (subscriptions && subscriptions?.subscriptions?.length > 0) {
            dispatch(setAllSubscriptions(subscriptions.subscriptions));
        } else if (subscriptions?.subscriptions?.length == 0) {
            refetchEpisodes();
        }
    }, [subscriptions]);

    useEffect(() => {
        if (prevSubscriptionCount === 0 && allSubscriptions.length === 1) {
            toast({
                title: "Success!",
                description:
                    "You added your first subscription. Tomorrow, you will receive a podcast with content from your new channels.",
                status: "success",
                duration: 10000,
                isClosable: true,
                position: "top",
            });
        }
        setPrevSubscriptionCount(allSubscriptions.length);
    }, [allSubscriptions.length, prevSubscriptionCount, toast]);

    const deleteSubscriptionHandler = async (id: string) => {
        setIsLoadingDeleteId(id);
        try {
            await deleteSubscription(id);
            dispatch(setAllSubscriptions(allSubscriptions.filter((item) => item._id !== id)));
        } catch (error) {
            setDeleteApiError("Something went wrong while deleting the subscriptions.");
        }
        setIsLoadingDeleteId("");
    };

    const primaryText = useColorModeValue("lightPrimary.500", "darkPrimary.500");
    const errorColor = useColorModeValue("red.500", "red.300");
    const textColor = useColorModeValue("gray.500", "gray.300");
    const backgroundWhite = useColorModeValue("white", "darkPrimary.100");
    const blackWhiteColor = useColorModeValue("black", "white");
    const darkWhiteBackgroundColor = useColorModeValue("white", "darkPrimary.100");
    const episodeBorderColor = useColorModeValue("white", "gray.600");

    const singleEpisode = episodes?.feeds?.[0]?.episodes?.[0];

    const { activeStep } = useSteps({
        index: -1,
        count: steps.length,
    });
    return (
        <Box pl={{ base: 0, md: 0, lg: 72 }} pt={{ base: "5", md: "20" }} bg={"primaryBg"}>
            <Flex justify={"center"} alignItems={"center"}>
                <Stack w={{ base: "90%", lg: "1020px" }} spacing={8}>
                    <Stack>
                        <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight={"bold"}>
                            {"Configure Your Podcast Feed"}
                        </Text>
                        <Text color={textColor} fontSize={{ base: "sm", md: "md" }}>
                            {
                                "On this page, you can add/remove channels from your daily episodes, view your current channels, and select your podcast host."
                            }
                        </Text>
                    </Stack>

                    {allSubscriptions.length === 0 && !isLoadingEpisode && !isErrorEpisode && singleEpisode && (
                        <Stack spacing={5} bg={"cardGradientBg"} p={4} mb={10} borderRadius={"lg"}>
                            <Text fontSize={"large"} fontWeight={"600"}>
                                Listen to your first pod
                            </Text>
                            <Flex
                                direction={{ base: "column", sm: "row" }}
                                borderRadius={"lg"}
                                p={3}
                                bg={darkWhiteBackgroundColor}
                                border={"1px solid"}
                                borderColor={episodeBorderColor}
                            >
                                <Stack
                                    direction={{ base: "column", sm: "row" }}
                                    align={"center"}
                                    spacing={6}
                                    w={"100%"}
                                >
                                    <Stack direction={"row"} align={"center"}>
                                        <Text>{"1"}</Text>
                                        <Image
                                            boxSize="90px"
                                            objectFit="fill"
                                            src={singleEpisode?.image_url}
                                            alt="podcast image"
                                            borderRadius={"md"}
                                        />
                                    </Stack>
                                    <VStack align={"flex-start"} spacing={2} w={"100%"}>
                                        <Flex
                                            direction={{ base: "column", lg: "row" }}
                                            justify={"space-between"}
                                            w={"100%"}
                                        >
                                            <Box>
                                                <Text fontSize={"small"} color={"gray"}>
                                                    {formatDate(singleEpisode?.pub_date || "") +
                                                        ", " +
                                                        splitTitle(singleEpisode?.title || "")?.[0]}
                                                </Text>{" "}
                                                <Text fontWeight={"500"}>
                                                    {splitTitle(singleEpisode?.title || "")?.[1]}
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <audio controls src={singleEpisode?.link || ""} style={{ width: "100%" }} />
                                    </VStack>
                                </Stack>
                            </Flex>
                        </Stack>
                    )}

                    <Stack direction={{ base: "column", xl: "row" }} spacing={5}>
                        <VStack
                            borderRadius={"12px"}
                            p={4}
                            w={{ base: "100%", xl: "65%" }}
                            align={"flex-start"}
                            bg={backgroundWhite}
                        >
                            <Text fontSize={"xl"} fontWeight={"bold"}>
                                Configure your pod by selecting at least 1 channel
                            </Text>

                            <Stepper
                                index={activeStep}
                                orientation="vertical"
                                h={{ base: "380px", lg: "250px" }}
                                gap="0"
                                w={"100%"}
                                mt={5}
                            >
                                {steps.map((step, index) => (
                                    <Step key={index} style={{ width: "100%" }}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>

                                        <Box ml={2} w={"100%"}>
                                            <Flex
                                                gap={{ base: 0, lg: 5 }}
                                                justify={"space-between"}
                                                direction={{ base: "column", lg: "row" }}
                                                w={"100%"}
                                                align={"flex-start"}
                                            >
                                                <Flex direction={"column"}>
                                                    <Stack
                                                        direction={{ base: "column", lg: "row" }}
                                                        align={"flex-start"}
                                                    >
                                                        <Tag size={"md"} variant="solid">
                                                            {step.name}
                                                        </Tag>
                                                        <Text fontSize={"large"} fontWeight={"500"}>
                                                            {step.title}
                                                        </Text>
                                                    </Stack>
                                                    <Text p={0} m={0} fontSize={"small"} color={"gray"} mb={2}>
                                                        {step.description}
                                                    </Text>
                                                </Flex>
                                                {index === 0 && (
                                                    <Button variant={"primary"} onClick={onOpenPopular} mb={2}>
                                                        Popular Channel
                                                    </Button>
                                                )}

                                                {index === 1 && (
                                                    <Link
                                                        href="https://www.youtube.com"
                                                        isExternal
                                                        color={"primary"}
                                                        mb={2}
                                                        mt={-2}
                                                    >
                                                        www.youtube.com <ExternalLinkIcon mx="2px" />
                                                    </Link>
                                                )}
                                            </Flex>
                                            {index === 1 && (
                                                <CreateSubscriptionForm
                                                    onAddSubscription={(value) => {
                                                        dispatch(setAllSubscriptions([...allSubscriptions, value]));
                                                    }}
                                                />
                                            )}
                                        </Box>

                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                        </VStack>
                        <VStack
                            borderRadius={"12px"}
                            p={4}
                            w={{ base: "100%", xl: "35%" }}
                            align={"flex-start"}
                            bg={backgroundWhite}
                        >
                            <Text fontSize={"xl"} fontWeight={"bold"}>
                                My Daily Pod Feed
                            </Text>
                            {!!error && (
                                <Text color={errorColor}>Something went wrong while fetching your subscriptions</Text>
                            )}
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <Stack w={"100%"} h={{ base: "380px", lg: "300px" }} overflowY={"auto"} pr={2}>
                                    {allSubscriptions.length === 0 ? (
                                        <Text color={"black"}>Add some channels to get started!.</Text>
                                    ) : (
                                        allSubscriptions.map((item, index) => (
                                            <React.Fragment key={item._id}>
                                                <Flex align={"center"} justify={"space-between"} flexWrap="wrap">
                                                    <HStack width={"80%"}>
                                                        <Avatar
                                                            name="channel_pic"
                                                            src={YoutubeLogo}
                                                            borderRadius={"none"}
                                                        />
                                                        <Link href={item.url} isExternal color={"primary"}>
                                                            {item.author}
                                                        </Link>
                                                    </HStack>
                                                    <IconButton
                                                        _hover={{ bg: backgroundWhite }}
                                                        aria-label="Delete subscription"
                                                        icon={<RiDeleteBin7Line />}
                                                        border={"none"}
                                                        bg={"backgroundWhite"}
                                                        color={blackWhiteColor}
                                                        onClick={() => deleteSubscriptionHandler(item._id)}
                                                        isLoading={item._id === isLoadingDeleteId}
                                                    />
                                                </Flex>
                                                {index !== allSubscriptions.length - 1 && (
                                                    <Divider borderBottomWidth={"1px"} borderColor={"gray.400"} />
                                                )}
                                            </React.Fragment>
                                        ))
                                    )}
                                </Stack>
                            )}
                        </VStack>
                    </Stack>
                    <Box p={4} borderRadius={"12px"} bgColor={backgroundWhite}>
                        <AllPodcastHost />
                    </Box>
                </Stack>
            </Flex>
            <AddCurrentSubscriptionModal isOpen={isOpen} onClose={onClose} />
            <AddPopularYouTubeChannelsModal isOpen={isOpenPopular} onClose={onClosePopular} />
        </Box>
    );
};

export default UserPreferencesPage;
