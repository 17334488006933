// Success.tsx
import React from 'react';
import { Box, Text, Flex, Button, Stack } from "@chakra-ui/react";
import { useNavigate, useLocation } from 'react-router-dom';

const Success = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    return (
        <Box pl={{ base: 0, md: 0, lg: 24 }}>
        <Flex justify={"center"} pt={"20"} alignItems={"center"}>
            <Stack w={{ base: "80%", md: "720px" }} spacing={8}>
                <Text fontSize="2xl" fontWeight="bold">
                    Subscription Successful!
                </Text>
                <Text>
                    Thank you for subscribing. Your session ID is {sessionId}.
                </Text>
                <Button colorScheme="teal" onClick={() => navigate('/manage-account')}>
                    Go to Manage Account
                </Button>
            </Stack>
        </Flex>
    </Box>
    );
};

export default Success;
