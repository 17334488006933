import { Flex, Text } from "@chakra-ui/react";

const NoPage = () => {
    return (
        <Flex align={"center"} justify={"center"} direction={"column"} pt={5}>
            <Text>404 page not found</Text>
        </Flex>
    );
};

export default NoPage;
