import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
    Flex,
    Link,
    List,
    ListItem,
    ListIcon,
    IconButton,
    useColorModeValue,
    CloseButton,
    Divider,
    BoxProps,
    FlexProps,
    useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, Link as ReachLink } from "react-router-dom";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import LoginPage from "../../components/Login";
import { LINKS } from "./links";
import Logo from "../../components/Logo/Logo";
import CreatePodFormHome from "../../components/CreatePodForm/CreateFormPodHome";
//import CreatePodForm from "../../components/CreatePodForm/CreatePodForm";

export default function HomePage() {
    const { isOpen, onClose } = useDisclosure();

    const textColor = useColorModeValue("primaryLight.100", "primaryDark.100");

    return (
        <Box bg="primaryBackground" height={"100vh"}>
            <SidebarContent onClose={onClose} display={{ base: isOpen ? "block" : "none", lg: "none" }} />
            <Container py={{ base: 2, lg: 5 }} maxW={"6xl"}>
                <Flex direction={"column"}>
                    <Box>
                        <Flex
                            mx={{ base: 3, lg: 0 }}
                            alignItems="center"
                            justify={"space-between"}
                            h="16"
                            display={{ base: "flex", lg: "none" }}
                        >
                            <Logo />
                        </Flex>
                        <Flex
                            display={{ base: "none", lg: "flex" }}
                            direction={"row"}
                            alignItems={"center"}
                            justify={"space-between"}
                        >
                            <Logo />
                        </Flex>
                    </Box>

                    <Stack
                        align={"center"}
                        spacing={{ base: 8, md: 10 }}
                        bg={"primaryBackground"}
                        mt={{ base: 6, lg: 28 }}
                        direction={{ base: "column", md: "row" }}
                    >
                        <Stack spacing={{ base: 5, md: 10 }} width={{ base: "100%", lg: "70%" }}>
                            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}>
                                <Text color={textColor} as={"span"}>
                                    Personalized Podcasts.
                                </Text>
                                <br />
                                <Text color={textColor} as={"span"}>
                                    Built for you, by you.
                                </Text>
                            </Heading>
                            <List spacing={3} fontSize={{ base: "lg", md: "xl" }} color={textColor}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    You tell us your favorite YouTube channels.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    We use AI to summarize the content into 3-5 minute podcasts.
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    {`Every day, you'll get a new podcast episode in your feed.`}
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Watch a 1-minute{" "}
                                    <Link
                                        color={"blue.400"}
                                        href={"https://www.youtube.com/watch?v=5zOQMoIbof8"}
                                        isExternal
                                    >
                                        demo
                                    </Link>{" "}
                                    to learn more.
                                </ListItem>
                            </List>
                            <LoginPage isMainHomePage={true} />
                        </Stack>
                        <Stack minWidth={{ base: "none", md: "500px" }}>
                            <CreatePodFormHome />
                        </Stack>
                    </Stack>
                </Flex>
            </Container>
        </Box>
    );
}

interface NavItemProps extends FlexProps {
    href: string;
    onClose: any;
    children: string;
}
const NavItem = ({ href, onClose, children, ...rest }: NavItemProps) => {
    const location = useLocation();
    const isActive = location.pathname === href;
    const [isHovered, setIsHovered] = useState(false);
    const isFilled = isHovered || isActive;

    return (
        <Flex
            justify={"space-between"}
            align="center"
            mx="2"
            mt="2"
            p="2"
            bg={isFilled ? "primary.500" : "transparent"}
            color={isFilled ? "white" : "primaryText"} // Color of Icons
            borderRadius={"md"}
            role="group"
            cursor="pointer"
            {...rest}
            direction={{ base: "row", lg: "column" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {" "}
            <Link as={ReachLink} to={href} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
                {children}
            </Link>
        </Flex>
    );
};

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={"lightBackground"}
            borderRight="0px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", lg: 24 }}
            color={"primaryText"}
            pos="fixed"
            // h={{ base: "450px", md: "full" }}
            sx={{
                overflow: "auto",
                "::-webkit-scrollbar": {
                    display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
            }}
            overflowY={"auto"}
            h="full"
            zIndex="1"
            {...rest}
        >
            <Box marginBottom="10px">
                <Flex h="20" alignItems="center" mx="6" justifyContent="space-between">
                    {/* <Image src={logo} alt="company logo" width={"30px"} /> */}
                    <CloseButton
                        bg="white"
                        p="5"
                        color="gray.400"
                        borderRadius={"sm"}
                        display={{ base: "flex", lg: "none" }}
                        onClick={onClose}
                    />
                </Flex>
            </Box>
            <Flex direction="column" gap={{ base: 10, lg: 0 }} justifyContent="space-between">
                <Box>
                    {LINKS.map((link) => (
                        <NavItem key={link.title} href={link.href} onClose={onClose}>
                            {link.title}
                        </NavItem>
                    ))}
                </Box>
                <Divider display={{ base: "block", lg: "none" }} color={"blackAlpha.700"} />
                <Box w={{ base: "full", lg: 24 }}>
                    <NavItem href={"login"} onClose={onClose}>
                        Log In
                    </NavItem>
                    <NavItem href={"login"} onClose={onClose}>
                        Sign Up
                    </NavItem>
                </Box>
                <Flex gap={0}>
                    <IconButton
                        marginTop="10px"
                        alignItems="center"
                        marginBottom="10px"
                        variant="outline"
                        ml="2"
                        border={0}
                        aria-label="Dark Mode Button"
                        icon={<BsTwitter color="gray" />}
                        // onClick={toggleColorMode}
                    />
                    <IconButton
                        marginTop="10px"
                        alignItems="center"
                        marginBottom="10px"
                        variant="outline"
                        border={0}
                        aria-label="Dark Mode Button"
                        icon={<BsLinkedin color="gray" />}
                        // onClick={toggleColorMode}
                    />
                </Flex>
            </Flex>
        </Box>
    );
};
