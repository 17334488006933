import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import SubscriptionsView from "./SubscriptionsView";
import NoSubscriptionsView from "./NoSubscriptionsView";

const Welcome = () => {
    const firstName = useSelector((state: RootState) => state?.user?.userProfile?.user?.user_info.firstname);
    const subscriptionCount = useSelector(
        (state: RootState) => state?.user?.userProfile?.user?.subscriptions.length || 0,
    );
    const episodeCount = useSelector((state: RootState) => state?.user?.episodeCount || 0);
    const navigate = useNavigate();

    useEffect(() => {
        if (subscriptionCount <= 0) {
            navigate('/user-preferences');
        }
    }, [subscriptionCount, navigate]);

    return (
        <Box pl={{ base: 0, md: 0, lg: 24 }}>
            <Flex justify={"center"} pt={"20"} alignItems={"center"}>
                {subscriptionCount > 0 ? (
                    <SubscriptionsView
                        firstName={firstName}
                        subscriptionCount={subscriptionCount}
                        episodeCount={episodeCount}
                    />
                ) : (
                    <NoSubscriptionsView firstName={firstName} />
                )}
            </Flex>
        </Box>
    );
};

export default Welcome; 
