import {
    Avatar,
    Box,
    Flex,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useColorModeValue,
    Button,
} from "@chakra-ui/react";
import { IExternalYoutubeSubscriptions, createSubscription } from "../../api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { setAllSubscriptions } from "../../reducers/userReducer";

type Props = {
    isOpen: boolean;
    onClose: any;
};

const AddCurrentSubscriptionModal = ({ isOpen, onClose }: Props) => {
    const externalChannels = useSelector(
        (state: RootState) => state.user.userProfile?.user?.external_youtube_subscriptions || [],
    );
    const allSubscriptions = useSelector((state: RootState) => state.user.allSubscriptions || []);
    const email = useSelector((state: RootState) => state.user.userProfile?.user.email || "");

    const [searchTerm, setSearchTerm] = useState("");
    const [filterOptions, setFilterOptions] = useState<IExternalYoutubeSubscriptions[]>(() => externalChannels);
    const [isLoadingId, setIsLoadingId] = useState("");
    const [apiError, setApiError] = useState("");
    const [showMessageModal, setShowMessageModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm.length > 0) {
                const lowerCaseST = searchTerm.toLowerCase();
                const filterData = externalChannels.filter((item) => item.author.toLowerCase().includes(lowerCaseST));
                setFilterOptions(filterData);
            } else if (externalChannels.length > 0) {
                setFilterOptions(externalChannels);
            }
        }, 1000);
        return () => clearTimeout(getData);
    }, [searchTerm]);

    useEffect(() => {
        if (isOpen && externalChannels.length === 0) {
            setShowMessageModal(true);
        }
    }, [isOpen, externalChannels.length]);

    const isAlreadyPresent = (channelId: string) => {
        return allSubscriptions.filter((item) => item?.additional_info?.channel_id === channelId).length > 0;
    };

    const onAddChannel = async (channelDetails: IExternalYoutubeSubscriptions) => {
        setApiError("");
        setIsLoadingId(channelDetails.channel_id);
        try {
            const response = await createSubscription({
                email,
                platform: "YOUTUBE",
                url: channelDetails.url,
            });
            dispatch(setAllSubscriptions([...allSubscriptions, response]));
        } catch (error) {
            setApiError("Something went wrong while adding subscription please try again.");
        }
        setIsLoadingId("");
    };

    const borderColor = useColorModeValue("gray.200", "gray.600");
    const linkColor = useColorModeValue("blue.500", "blue.300");
    const errorColor = useColorModeValue("red.500", "red.300");
    const iconButtonBg = useColorModeValue("secondary.900", "secondary.700");
    const iconButtonHoverBg = useColorModeValue("blue.400", "blue.500");

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Current Subscriptions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <Box>
                                <InputGroup size="md">
                                    <Input
                                        value={searchTerm}
                                        placeholder="Search by channel name"
                                        size="md"
                                        onChange={(event) => setSearchTerm(event.target.value)}
                                    />
                                    {searchTerm.length > 0 && (
                                        <InputRightElement width="4.5rem">
                                            <IconButton
                                                aria-label="clear user search"
                                                h="1.75rem"
                                                size="sm"
                                                icon={<CloseIcon />}
                                                variant={"unstyled"}
                                                onClick={() => setSearchTerm("")}
                                            />
                                        </InputRightElement>
                                    )}
                                </InputGroup>
                            </Box>
                            {!!apiError && (
                                <Stack>
                                    <Text color={errorColor}>{apiError}</Text>
                                </Stack>
                            )}

                            {filterOptions.map((item) => (
                                <Flex
                                    key={item._id}
                                    border={"1px"}
                                    borderStyle={"solid"}
                                    borderColor={borderColor}
                                    borderRadius={"md"}
                                    padding={2}
                                    align={"center"}
                                    justify={"space-between"}
                                    mb={3}
                                >
                                    <HStack>
                                        <Avatar name="channel_pic" src={item.thumbnail_url_default} borderRadius={"none"} />
                                        <Link href={item.url} isExternal color={linkColor}>
                                            {item.author}
                                        </Link>
                                    </HStack>
                                    <Stack>
                                        {isAlreadyPresent(item.channel_id) ? (
                                            <Box mr={2}>
                                                <CheckIcon color={"green.400"} />
                                            </Box>
                                        ) : (
                                            <IconButton
                                                size={"sm"}
                                                bg={iconButtonBg}
                                                _hover={{
                                                    backgroundColor: iconButtonHoverBg,
                                                }}
                                                aria-label="add channel"
                                                icon={<AddIcon />}
                                                onClick={() => {
                                                    onAddChannel(item);
                                                }}
                                                isLoading={isLoadingId === item.channel_id}
                                            />
                                        )}
                                    </Stack>
                                </Flex>
                            ))}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={showMessageModal} onClose={() => setShowMessageModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>No YouTube Subscriptions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            {`You don't have any YouTube subscriptions, so this list is empty. Please instead add popular
                            YouTube channels, or add channels manually.`}
                        </Text>
                        <Button mt={4} onClick={() => setShowMessageModal(false)}>
                            Close
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddCurrentSubscriptionModal;
