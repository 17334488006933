import { Stack, Box, AspectRatio, Button, Text, List, ListItem, ListIcon, Link, useColorModeValue } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { Link as ReachLink } from "react-router-dom";
// import Button from "../../themes/components/button";
import { CogIcon, PlusCircleIcon, MusicalNoteIcon } from "@heroicons/react/24/outline";

type NoSubscriptionsViewProps = {
    firstName?: string;
};

const NoSubscriptionsView = ({ firstName }: NoSubscriptionsViewProps) => {
    const textColor = useColorModeValue("black", "white");
    const linkColor = useColorModeValue("blue.500", "blue.300");
    const listIconColor = useColorModeValue("green.500", "green.300");
    const buttonColor = useColorModeValue("accent.light.400", "accent.dark.400");

    return (
        <Stack w={{ base: "80%", md: "720px" }} spacing={8}>
            <Text fontSize="4xl" fontWeight="bold" color={textColor}>
                {firstName ? `Welcome, ${firstName}!` : "Welcome!"}
            </Text>
            <Text fontSize="xl" color={textColor}>
                <strong>{`First things first:  take a listen to your Welcome Podcast`}</strong>
            </Text>

            <List spacing={3} fontSize="xl" color={textColor}>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`Click on the  `}
                    <Button 
                        as={ReachLink} 
                        width={{ base: "100%", md: "25%" }}
                        to="/listen-to-episodes" 
                        variant={"accent"}
                        bg={buttonColor}
                        borderRadius={"md"}
                        boxShadow={"lg"}
                        borderColor={buttonColor}
                        size="sm" 
                        leftIcon={<MusicalNoteIcon style={{ width: 20, height: 20 }} />}>
                        Listen to Episodes
                    </Button>
                    {` link in the navigation bar.`}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`You should see a podcast in your podcast feed.  Take a listen, then come back here.`}
                </ListItem>
                {/* <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`Then come back here and we can continue with your setup.`}
                </ListItem> */}
            </List>
            
            <Text fontSize="xl" color={textColor}>
                <strong>{`Next, let's add some channels to your feed so you can receive your daily podcast`}</strong>
            </Text>

            <List spacing={3} fontSize="xl" color={textColor}>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`First, select the `}
                    <Button 
                        as={ReachLink} 
                        width={{ base: "100%", md: "25%" }}
                        to="/user-preferences" 
                        variant={"accent"}
                        bg={buttonColor}
                        size="sm"
                        borderRadius={"md"}
                        boxShadow={"lg"}
                        borderColor={buttonColor} 
                        leftIcon={<CogIcon style={{ width: 20, height: 20 }} />}>
                        Configure Podcast Feed
                    </Button>
                    {` link in the navigation bar.`}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`You can add your YouTube channels, popular channels, or select channels manually.`}
                </ListItem>
                <ListItem>
                    <ListIcon as={CheckIcon} color={listIconColor} />
                    {`See the short video below for a walkthrough.`}
                </ListItem>
            </List>
            <Box>
                <video controls width="100%">
                    <source src="/quick-setup.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>
        </Stack>


    );
};

export default NoSubscriptionsView;
