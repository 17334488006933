import {
    ICreatePodRequest,
    ICreateSubscriptionRequest,
    IFetchEpisodesResponse,
    IGetSubscriptionResponse,
    IGenerateAnonPodcastResponse,
    IHostResponse,
    ILoginResponse,
    ISingleSubscription,
    ISingleShareEpisode,
} from "./models";

const BACKEND_URL = import.meta.env.VITE_APP_BACKEND_URL as string;
const FRONTEND_URL = import.meta.env.VITE_APP_FRONTEND_URL;

// My daily pod APIs calls
export async function loginWithGoogleToken(token: string): Promise<ILoginResponse> {
    const response = await fetch(`${BACKEND_URL}/login`, {
        method: "POST",
        body: JSON.stringify({ code: token, redirect_uri: FRONTEND_URL }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const parsedResponse: ILoginResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function createPodNon(url: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/generate_podcast_anon?url=${url}`, {
        method: "POST",
    });

    const parsedResponse: IGenerateAnonPodcastResponse = await response.json();

    if (response.status > 299 || !response.ok) {
        throw new Error("Unknown error");
    }

    if (!parsedResponse.success) {
        throw new Error(parsedResponse.message);
    }

    if (parsedResponse.data === null) {
        throw new Error("Podcast URL is null.");
    }

    return parsedResponse.data;
}

export async function createPodForUser(payload: ICreatePodRequest): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/generate_podcasts_on_demand`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const parsedResponse: string = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function fetchPodcastEpisodes(email: string): Promise<IFetchEpisodesResponse> {
    const response = await fetch(`${BACKEND_URL}/podcast/byemail/${email}`, {
        method: "GET",
    });

    const parsedResponse: IFetchEpisodesResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function createSubscription(payload: ICreateSubscriptionRequest): Promise<ISingleSubscription> {
    const response = await fetch(`${BACKEND_URL}/subscriptions/create`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const parsedResponse: ISingleSubscription = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function getSubscription(email: string): Promise<IGetSubscriptionResponse> {
    const response = await fetch(`${BACKEND_URL}/subscriptions/byemail/${email}`, {
        method: "GET",
    });

    const parsedResponse: IGetSubscriptionResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function deleteSubscription(subscription_id: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/subscriptions/${subscription_id}`, {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status === 204) {
        return "ok";
    }

    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return "Ok";
}

export async function getUserByEmail(email: string): Promise<IFetchEpisodesResponse> {
    const response = await fetch(`${BACKEND_URL}/users/byemail/${email}`, {
        method: "GET",
    });

    const parsedResponse: IFetchEpisodesResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function getAllHost(): Promise<IHostResponse> {
    const response = await fetch(`${BACKEND_URL}/hosts/byattributes`, {
        method: "POST",
        body: JSON.stringify({ status: "ACTIVE" }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const parsedResponse: IHostResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function updateUserPodConfig(email: string, host: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/users/${email}`, {
        method: "PUT",
        body: JSON.stringify({
            email,
            user_podcast_configs: {
                host_is_preconfigured: true,
                host,
            },
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const parsedResponse: string = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function createCheckoutSession(email: string, productId: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/products/create-checkout-session`, {
        method: "POST",
        body: JSON.stringify({ email, productId }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status > 299 || !response.ok) {
        throw new Error("Failed to create checkout session");
    }

    const parsedResponse: { url: string } = await response.json(); 
    return parsedResponse.url;
}

export async function createPortalSession(email: string): Promise<string> {
    const response = await fetch(`${BACKEND_URL}/products/create-portal-session`, {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status > 299 || !response.ok) {
        throw new Error("Failed to create billing portal session");
    }

    const parsedResponse: { url: string } = await response.json();
    return parsedResponse.url;
}

export async function getUserEpisode(episodeId: string): Promise<ISingleShareEpisode> {
    const response = await fetch(`${BACKEND_URL}/podcast/feeds/episodes/${episodeId}`, {
        method: "GET",
    });

    if (response.status > 299 || !response.ok) {
        throw new Error("Failed to create fetched the episode.");
    }

    const parsedResponse = await response.json();
    return parsedResponse;
}

export async function addToFavoritePod(email: string, episodeId: string, isFavorite: boolean): Promise<any> {
    const response = await fetch(`${BACKEND_URL}/podcast/feeds/episodes/${episodeId}/favorite`, {
        method: "PATCH",
        body: JSON.stringify({ email: email, is_favorite: isFavorite }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status > 299 || !response.ok) {
        throw new Error("Failed to add episode to favorite.");
    }

    const parsedResponse = await response.json();
    return parsedResponse;
}

export const deleteAccount = async (userId: string): Promise<void> => {
    const response = await fetch(`${BACKEND_URL}/users/${userId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include", // This ensures cookies are sent with the request
    });

    if (response.status > 299 || !response.ok) {
        throw new Error("Failed to delete account");
    }

    // If the deletion was successful, we don't need to return anything
};
