// Badge.tsx
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

type BadgeProps = {
    text: string;
    count: number;
};

const Badge = ({ count, text }: BadgeProps) => {
    const bg = useColorModeValue("green.100", "green.900");
    const borderColor = useColorModeValue("green.200", "green.700");
    const textColor = useColorModeValue("black", "white");
    const iconColor = useColorModeValue("green.500", "green.300");

    return (
        <Box
            borderWidth={1}
            overflow="hidden"
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderRadius={"lg"}
            boxShadow={"lg"}
            bg={bg}
            borderColor={borderColor}
            w="100%" 
        >
            <Text fontSize="xl" fontWeight="bold" color={textColor}>
                {text}
            </Text>
            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                {count}
            </Text>
            <CheckCircleIcon w={8} h={8} color={iconColor} />
        </Box>
    );
};

export default Badge;
