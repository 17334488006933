import { Box, Flex, HStack, Image, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import Logo from "../../components/Logo/Logo";
import "react-h5-audio-player/lib/styles.css";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getUserEpisode } from "../../api";
import Loading from "../../components/Loading/Loading";
import { useEffect } from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface Props {
    isInsideAuth: boolean;
}

const SharePod = ({ isInsideAuth }: Props) => {
    const rssLinkColor = useColorModeValue("blue.600", "blue.300");
    const errorColor = useColorModeValue("red.500", "red.300");

    const { id } = useParams();

    const { data, isError, isLoading, refetch } = useQuery(["getUserEpisode", id], () => getUserEpisode(id || ""), {
        enabled: false, // Disable automatic fetching on component mount
    });

    useEffect(() => {
        if (id) {
            refetch();
        }
    }, [id]);

    return (
        <Box pl={{ base: 0, md: 0, lg: isInsideAuth ? 32 : 0 }} pt={isInsideAuth ? "20" : "5"} bg={"primary.50"}>
            <Flex justify={"center"} alignItems={"center"} bg={"primary.50"}>
                <Stack w={{ base: "90%", lg: "1020px" }} spacing={8}>
                    {!isInsideAuth && (
                        <Box>
                            <Flex alignItems="center" justify={"space-between"} h="16">
                                <Logo />
                            </Flex>
                        </Box>
                    )}

                    <Stack
                        align={"center"}
                        bg={"primaryBackground"}
                        direction={"column"}
                        spacing={{ base: 4, lg: 4 }}
                        justifyContent={"center"}
                        flex={1}
                        display={"flex"}
                    >
                        {isLoading && <Loading />}
                        {isError && (
                            <Stack>
                                <Text
                                    color={errorColor}
                                >{`Something went wrong while fetching the episode with this id: ${id}`}</Text>
                            </Stack>
                        )}

                        {!isLoading && !isError && (
                            <>
                                <Box textAlign={"center"}>
                                    <Text fontSize={"2xl"} fontWeight={"bold"}>
                                        {data?.author || ""}
                                    </Text>
                                    <Text fontSize={"xl"} fontWeight={"bold"}>
                                        {data?.title || ""}
                                    </Text>
                                </Box>
                                <Flex align={"center"} direction={"column"} w={{ base: "100%", md: "2xl" }} gap={2}>
                                    <Image src={data?.image_url} width={"250px"} height={"250px"} />
                                    {/* <AudioPlayer src={data?.link} volume={0.5} layout="stacked" /> */}
                                    <audio controls src={data?.link} style={{ width: "100%" }} />

                                    {!isInsideAuth && (
                                        <Box pt={5}>
                                            <Link href={"/"} color={rssLinkColor} fontWeight={"bold"}>
                                                {"Enjoy this pod? Create a My Daily Pod"}
                                            </Link>
                                        </Box>
                                    )}
                                    <Stack mt={2} width={"100%"}>
                                        <Text fontWeight={"bold"}>Sources: </Text>
                                        {data?.content_sources && data.content_sources.length > 0 ? (
                                            data.content_sources.map((source) => (
                                                <HStack key={source._id}>
                                                    <Text fontWeight={"400"}>{`${source.channel_author}: `}</Text>
                                                    <Link
                                                        href={source.video_url}
                                                        isExternal
                                                        fontSize={"small"}
                                                        color={rssLinkColor}
                                                    >
                                                        {source.title} <ExternalLinkIcon mx="2px" />
                                                    </Link>
                                                </HStack>
                                            ))
                                        ) : (
                                            <Text>No sources available</Text>
                                        )}
                                    </Stack>
                                    <Stack mt={2} width={"100%"} pb={10}>
                                        <Text fontWeight={"bold"}>Description: </Text>
                                        {data?.description && data.description.length > 0 ? (
                                            <Text overflowY={"auto"} h={"300px"}>
                                                {data.description}
                                            </Text>
                                        ) : (
                                            <Text>No description available</Text>
                                        )}
                                    </Stack>
                                </Flex>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Flex>
        </Box>
    );
};

export default SharePod;
