import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Button,
    FormControl,
    FormErrorMessage,
    Textarea,
    Stack,
    Text,
    Box,
    useColorModeValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { createPodForUser } from "../../api";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

type FormState = {
    urls: string;
};

const CreatePodFormUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [singleMP3, setSingleMP3] = useState("");
    const [apiErrorMessage, setAPIErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [processingText, setProcessingText] = useState("Processing");
    const rotatingTexts = [
        "Robots hard at work.",
        "Configurating stuff.",
        "Ones and zeros being generated.",
        "AI doing AI things.",
        "Pushing data through the tubes.",
    ];
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormState>();
    const email = useSelector((state: RootState) => state?.user?.userProfile?.user?.email || "");

    const onHandleSubmit = async (data: FormState) => {
        setAPIErrorMessage("");
        setIsLoading(true);
        setShowModal(true);
        try {
            const multipleUrls = data.urls.split("\n");
            const response = await createPodForUser({
                email,
                content_requests: multipleUrls.map((item) => ({
                    url: item,
                    platform: "YOUTUBE",
                })),
                delivery_type: "NOW",
            });
            setSingleMP3(response);
            reset();
        } catch (error) {
            setAPIErrorMessage("Something went wrong while generating the podcast.");
        }
        setIsLoading(false);
        setShowModal(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * rotatingTexts.length);
            setProcessingText(rotatingTexts[randomIndex]);
        }, 5000); // Change text every 5 seconds

        return () => clearInterval(interval);
    }, []);

    const primaryBg = useColorModeValue("lightPrimary.100", "darkPrimary.100");
    const primaryBorder = useColorModeValue("lightPrimary.400", "darkPrimary.400");
    const primaryText = useColorModeValue("lightPrimary.900", "darkPrimary.900");
    const errorColor = useColorModeValue("red.500", "red.300");
    const inputBg = useColorModeValue("lightPrimary.50", "darkPrimary.50");
    const mainContainerBg = useColorModeValue("white", "darkPrimary.100");

    return (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Stack flex={1} w={"full"} spacing={5} p={4} bg={mainContainerBg} borderRadius={"12px"}>
                <Stack backgroundColor={mainContainerBg} height={"full"} spacing={5}>
                    <Stack>
                        <Text fontSize={"2xl"} fontWeight={"bold"}>
                            Create On Demand Podcast
                        </Text>
                        <Text color={primaryText} fontSize={"m"}>
                            Enter URL to a YouTube Video (one URL per line)
                        </Text>
                    </Stack>
                    <Box
                        borderStyle={"solid"}
                        borderWidth={"0px"}
                        borderColor={primaryBorder}
                        p={4}
                        borderRadius={"lg"}
                        // boxShadow={"lg"}
                        bgColor={mainContainerBg}
                    >
                        <Text color={primaryText} fontSize={"m"}>
                            {/* {`- Paste one or more YouTube video urls in the box below.`}
                            <br />
                            {`- Example video would look like this: https://www.youtube.com/watch?v=Lm3Bntsp2ck`}
                            <br />
                            {`- Need more help?  Check out our FAQ page for more information.`} */}
                        </Text>
                        <FormControl isInvalid={errors.urls ? true : false}>
                            <Textarea
                                placeholder="Example: https://www.youtube.com/watch?v=Lm3Bntsp2ck"
                                bgColor={inputBg}
                                {...register("urls", {
                                    required: "Content is required",
                                    pattern: {
                                        value: /^(https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[^\s]*)?(\n|$))+/,
                                        message:
                                            "Please enter a valid URL. Example format: https://www.youtube.com/watch?v=Lm3Bntsp2ck",
                                    },
                                })}
                                isInvalid={errors.urls ? true : false}
                            />
                            {!!errors.urls && <FormErrorMessage>{errors.urls.message}</FormErrorMessage>}
                        </FormControl>
                    </Box>

                    {!!singleMP3 && (
                        <Stack>
                            <Text fontSize={"sm"}>Generated Podcast</Text>
                            <audio style={{ width: "100%" }} controls src={singleMP3} />
                        </Stack>
                    )}

                    {!!apiErrorMessage && (
                        <Stack>
                            <Text color={errorColor} fontSize={"sm"}>
                                {apiErrorMessage}
                            </Text>
                        </Stack>
                    )}

                    <Button
                        width={{ base: "100%", md: "40%" }}
                        rightIcon={<ArrowForwardIcon />}
                        fontSize={{ base: "sm", md: "inherit" }}
                        variant={"primary"}
                        type="submit"
                        isLoading={isLoading}
                        isDisabled={isLoading}
                    >
                        Generate Pod
                    </Button>
                </Stack>

                {/* <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={"2xl"} scrollBehavior={"inside"}>
                    <ModalOverlay />
                    <ModalContent style={{ overflow: "auto" }}>
                        <ModalHeader>Generating Podcast</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box textAlign="center">
                                <Text>{processingText}</Text>
                            </Box>
                            <Progress size="lg" isIndeterminate hasStripe={true} />
                        </ModalBody>
                        <ModalFooter>
                            <Alert status="info" alignItems={"flex-start"}>
                                <Text color={primaryText}>
                                    {`Hang tight! Your custom pod is being created and will be ready shortly.`}
                                </Text>
                            </Alert>
                        </ModalFooter>
                    </ModalContent>
                </Modal> */}

                {isLoading && (
                    <Alert status="info" alignItems={"flex-start"}>
                        <AlertIcon />
                        <Text>{`Hang tight! Your custom pod is being created and will be ready shortly.`}</Text>
                    </Alert>
                )}
            </Stack>
        </form>
    );
};

export default CreatePodFormUser;
