const semanticTokens = {
    colors: {
        background: {
            default: "white",
            _dark: "gray.900",
        },
        text: {
            default: "black",
            _dark: "white",
        },
        primary: {
            default: "lightPrimary.500",
            _dark: "darkPrimary.500",
        },
        "primary.50": {
            default: "lightPrimary.50",
            _dark: "darkPrimary.50",
        },
        "primary.100": {
            default: "lightPrimary.100",
            _dark: "darkPrimary.100",
        },
        "primary.200": {
            default: "lightPrimary.200",
            _dark: "darkPrimary.200",
        },
        "primary.300": {
            default: "lightPrimary.300",
            _dark: "darkPrimary.300",
        },
        "primary.400": {
            default: "lightPrimary.400",
            _dark: "darkPrimary.400",
        },
        "primary.500": {
            default: "lightPrimary.500",
            _dark: "darkPrimary.500",
        },
        "primary.600": {
            default: "lightPrimary.600",
            _dark: "darkPrimary.600",
        },
        "primary.700": {
            default: "lightPrimary.700",
            _dark: "darkPrimary.700",
        },
        "primary.800": {
            default: "lightPrimary.800",
            _dark: "darkPrimary.800",
        },
        "primary.900": {
            default: "lightPrimary.900",
            _dark: "darkPrimary.900",
        },
        border: {
            default: "gray.300",
            _dark: "gray.600",
        },
        rssLink: {
            default: "blue.600",
            _dark: "blue.300",
        },
        error: {
            default: "red.500",
            _dark: "red.300",
        },
        modalBg: {
            default: "white",
            _dark: "gray.800",
        },
        modalText: {
            default: "gray.800",
            _dark: "gray.300",
        },
        itemBg: {
            default: "lightPrimary.200",
            _dark: "darkPrimary.200",
        },
        itemHoverBg: {
            default: "lightPrimary.100",
            _dark: "darkPrimary.100",
        },
        primaryText: {
            default: "lightPrimary.500",
            _dark: "darkPrimary.500",
        },
    },
};

export default semanticTokens;
