import {
    Text,
    Box,
    Flex,
    Stack,
    IconButton,
    useToast,
    HStack,
    Link,
    Divider,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useColorModeValue,
    Image,
    VStack,
    Icon,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { ArrowForwardIcon, CopyIcon, ExternalLinkIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import Loading from "../../components/Loading/Loading";
import { useQuery } from "react-query";
import { ISingleFeedEpisode, addToFavoritePod, fetchPodcastEpisodes } from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ISingleEpisodes } from "../../api/";
import amplitude from "../../utils/amplitude";
import { IoMdShare } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { formatDate, splitTitle } from "../../utils/helper";

const FRONTEND_URL = import.meta.env.VITE_APP_FRONTEND_URL;

const ListenToEpisodes = () => {
    const email = useSelector((state: RootState) => state?.user?.userProfile?.user?.email || "");
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedEpisode, setSelectedEpisode] = useState<ISingleEpisodes["episodes"][0] | null>(null);
    const [sortedEpisodes, setSortedEpisodes] = useState<ISingleFeedEpisode[]>([]);
    const [isLoadingHeartId, setIsLoadingHeartId] = useState("");
    const [apiError, setApiError] = useState("");

    const {
        data: episodes,
        error,
        isLoading,
        refetch,
    } = useQuery(["podEpisodes", email], () => fetchPodcastEpisodes(email), {
        enabled: false, // Disable automatic fetching on component mount
    });

    useEffect(() => {
        if (email.length > 0) {
            refetch();
        }
    }, [email]);

    useEffect(() => {
        if (episodes) {
            amplitude.track("EpisodesFetched", {
                totalEpisodes: episodes?.feeds?.[0]?.episodes?.length,
            });
        }
    }, [episodes]);

    const rssLink = episodes?.feeds?.[0]?.link;

    useEffect(() => {
        if (episodes?.feeds?.[0]?.episodes?.length) {
            const tempEpisodes = episodes?.feeds?.[0]?.episodes.sort((a, b) => {
                const dateA: any = new Date(a.pub_date);
                const dateB: any = new Date(b.pub_date);
                return dateB - dateA;
            });
            setSortedEpisodes(tempEpisodes);
        } else {
            setSortedEpisodes([]);
        }
    }, [episodes]);

    const copyResponseToClipboard = () => {
        navigator.clipboard.writeText(rssLink || "");
        toast({
            title: "",
            description: "Link copied to clipboard successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    };

    const onClickShare = (episodeId: string) => {
        const shareLink = `${FRONTEND_URL}/share/${episodeId}`;
        navigator.clipboard.writeText(shareLink || "");
        toast({
            title: "",
            description: "Link copied to clipboard successfully.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
    };

    const onClickHeart = async (id: string, isFavorite: boolean) => {
        setIsLoadingHeartId(id);
        try {
            await addToFavoritePod(email, id, isFavorite);
            setSortedEpisodes((oldItems) =>
                oldItems.map((item) => {
                    if (item._id === id) {
                        return {
                            ...item,
                            is_favorite: isFavorite,
                        };
                    }
                    return item;
                }),
            );
        } catch (error) {
            setApiError("Something went wrong while adding episode to favorite.");
        }
        setIsLoadingHeartId("");
    };

    const getTypeFromTitle = (title: string) => {
        if (title.includes("Daily")) return "Daily";
        if (title.includes("Weekly")) return "Weekly";
        if (title.includes("On Demand")) return "On Demand";
        return "";
    };

    const openEpisodeModal = (episode: ISingleEpisodes["episodes"][0]) => {
        setSelectedEpisode(episode);
        onOpen();
    };

    const borderColor = useColorModeValue("gray.300", "gray.600");
    const rssLinkColor = useColorModeValue("blue.600", "blue.300");
    const errorColor = useColorModeValue("red.500", "red.300");
    const modalBgColor = useColorModeValue("white", "gray.800");
    const modalTextColor = useColorModeValue("gray.800", "gray.300");
    const backgroundWhite = useColorModeValue("white", "darkPrimary.100");
    const backgroundGradientWhite = useColorModeValue("primaryGradientBg", "darkPrimary.100");
    const mainContainerBorderColor = useColorModeValue("borderMercury", "gray.600");
    const darkWhiteBackgroundColor = useColorModeValue("primaryLightBg", "darkBackgroundOnTop");
    const episodeBorderColor = useColorModeValue("white", "gray.600");

    const getOptionsForPlayer = useCallback((episode: any, showForMobile: boolean, loadingId: string) => {
        return (
            <HStack
                spacing={0}
                align={"flex-start"}
                justify={{ base: "flex-end", md: "flex-start" }}
                display={showForMobile ? { base: "flex", md: "none" } : { base: "none", md: "block" }}
            >
                <IconButton
                    variant="elevated"
                    aria-label="add to favorite podcast"
                    icon={episode.is_favorite ? <Icon as={FaHeart} /> : <FaRegHeart />}
                    size={"sm"}
                    onClick={() => onClickHeart(episode._id, !episode.is_favorite)}
                    isLoading={episode._id === loadingId}
                />
                <IconButton
                    variant="elevated"
                    aria-label="info podcast"
                    icon={<InfoOutlineIcon />}
                    size={"sm"}
                    onClick={() => openEpisodeModal(episode)}
                />
                <IconButton
                    variant="elevated"
                    aria-label="share podcast"
                    icon={<IoMdShare />}
                    size={"sm"}
                    onClick={() => onClickShare(episode._id)}
                />
            </HStack>
        );
    }, []);

    return (
        <Box pl={{ base: 0, md: 0, lg: 72 }} pt={"20"} bg={"primary.50"}>
            <Flex justify={"center"} alignItems={"center"} bg={"primary.50"}>
                <Stack w={{ base: "90%", lg: "1020px" }} spacing={8}>
                    <Stack>
                        <Text fontSize={"2xl"} fontWeight={"bold"}>
                            {"Listen to Episodes"}
                        </Text>
                        <Text color={"gray.500"} fontSize={"m"}>
                            {"Listen below, or configure your favorite podcast player with RSS feed below"}
                        </Text>
                    </Stack>

                    {isLoading ? (
                        <Box height={"100vh"}>
                            <Loading />
                        </Box>
                    ) : (
                        <>
                            {!!error ? (
                                <Text color={errorColor}>Something went wrong while fetching your episodes.</Text>
                            ) : (
                                <>
                                    <Box
                                        borderStyle={"solid"}
                                        borderWidth={"1px"}
                                        borderColor={borderColor}
                                        borderRadius={"lg"}
                                        p={4}
                                        bgColor={backgroundWhite}
                                    >
                                        <Stack
                                            direction={{ base: "column", md: "row" }}
                                            gap={{ base: 2, md: 10 }}
                                            align={{ base: "flex-start", md: "center" }}
                                            justify={{ base: "flex-start", md: "center" }}
                                        >
                                            <Flex>
                                                <Stack direction={"row"} gap={2} align={"center"}>
                                                    <Box>
                                                        <Image src={"/applePodcast.png"} />
                                                    </Box>
                                                    <Box>
                                                        <Link
                                                            as={"a"}
                                                            isExternal
                                                            href="https://docs.mydailypod.com/listen-apple-podcast-app/"
                                                            color={"primary"}
                                                        >
                                                            Configure Apple Podcast
                                                            <ArrowForwardIcon color={rssLinkColor} ml={2} />
                                                        </Link>
                                                    </Box>
                                                </Stack>
                                            </Flex>

                                            <Flex>
                                                <Stack direction={"row"} gap={2} align={"center"}>
                                                    <Box>
                                                        <Image src={"/castFmPodcast.png"} />
                                                    </Box>
                                                    <Box>
                                                        <Link
                                                            as={"a"}
                                                            isExternal
                                                            href="https://docs.mydailypod.com/listen-overcast-app/"
                                                            color={"primary"}
                                                        >
                                                            Configure Overcast FM
                                                            <ArrowForwardIcon color={rssLinkColor} ml={2} />
                                                        </Link>
                                                    </Box>
                                                </Stack>
                                            </Flex>
                                        </Stack>

                                        <HStack alignItems={"center"} mt={2}>
                                            <Text fontSize={"sm"} wordBreak={"break-word"}>
                                                <Text as={"span"} color={"primary"}>
                                                    {"Your RSS Feed: "}
                                                </Text>
                                                {rssLink}
                                            </Text>
                                            <IconButton
                                                onClick={() => copyResponseToClipboard()}
                                                aria-label="copy rss link"
                                                icon={<CopyIcon />}
                                                variant={"unstyled"}
                                            />
                                        </HStack>
                                        <HStack>
                                            <Text fontSize="sm" fontStyle="italic" color={"primary.900"}>
                                                Note: it might take 30 minutes for new accounts to access the podcast
                                                feed.
                                            </Text>
                                        </HStack>
                                    </Box>

                                    <Stack
                                        spacing={5}
                                        bg={backgroundGradientWhite}
                                        p={4}
                                        border={"1px solid"}
                                        borderColor={mainContainerBorderColor}
                                        borderRadius={"12px"}
                                        mb={10}
                                    >
                                        <Text fontSize={"large"} fontWeight={"600"}>
                                            Listen
                                        </Text>
                                        {sortedEpisodes?.map((item, index) => {
                                            const [titleFirstPart, titleSecondPart] = splitTitle(item.title);
                                            return (
                                                <Flex
                                                    key={item._id}
                                                    direction={{ base: "column", sm: "row" }}
                                                    borderRadius={"lg"}
                                                    p={3}
                                                    bg={darkWhiteBackgroundColor}
                                                    border={"1px solid"}
                                                    borderColor={episodeBorderColor}
                                                >
                                                    <Stack
                                                        direction={{ base: "column", sm: "row" }}
                                                        align={"center"}
                                                        spacing={6}
                                                        w={"100%"}
                                                    >
                                                        <Stack
                                                            direction={"row"}
                                                            align={{ base: "flex-start", md: "center" }}
                                                            justify={{ base: "space-between" }}
                                                            w={{ base: "100%", md: "auto" }}
                                                        >
                                                            <Flex gap={2} align={{ base: "flex-start", md: "center" }}>
                                                                <Text>{item?.episode || index + 1}</Text>
                                                                <Image
                                                                    boxSize="90px"
                                                                    objectFit="fill"
                                                                    src={item.image_url}
                                                                    alt="podcast image"
                                                                    borderRadius={"md"}
                                                                />
                                                            </Flex>
                                                            {getOptionsForPlayer(item, true, isLoadingHeartId)}
                                                        </Stack>
                                                        <VStack align={"flex-start"} spacing={2} w={"100%"}>
                                                            <Flex
                                                                direction={{ base: "column", lg: "row" }}
                                                                justify={"space-between"}
                                                                w={"100%"}
                                                            >
                                                                <Box>
                                                                    <Text fontSize={"small"} color={"gray"}>
                                                                        {formatDate(item.pub_date) +
                                                                            ", " +
                                                                            titleFirstPart}
                                                                    </Text>
                                                                    <Text fontWeight={"500"}>{titleSecondPart}</Text>
                                                                </Box>
                                                                {getOptionsForPlayer(item, false, isLoadingHeartId)}
                                                            </Flex>
                                                            <audio controls src={item.link} style={{ width: "100%" }} />
                                                        </VStack>
                                                    </Stack>
                                                </Flex>
                                            );
                                        })}
                                    </Stack>
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </Flex>

            {selectedEpisode && (
                <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} scrollBehavior={"inside"}>
                    <ModalOverlay />
                    <ModalContent bgColor={modalBgColor} color={modalTextColor}>
                        <ModalHeader>{selectedEpisode.title}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text fontWeight={"normal"}>{selectedEpisode.description}</Text>
                            <Stack mt={2}>
                                <Text fontWeight={"bold"}>Sources: </Text>
                                {selectedEpisode.content_sources && selectedEpisode.content_sources.length > 0 ? (
                                    selectedEpisode.content_sources.map((source) => (
                                        <HStack key={source._id}>
                                            <Text fontWeight={"400"}>{`${source.channel_author}: `}</Text>
                                            <Link
                                                href={source.video_url}
                                                isExternal
                                                fontSize={"small"}
                                                color={rssLinkColor}
                                            >
                                                {source.title} <ExternalLinkIcon mx="2px" />
                                            </Link>
                                        </HStack>
                                    ))
                                ) : (
                                    <Text>No sources available</Text>
                                )}
                            </Stack>
                            <Stack mt={2}>
                                <Divider borderBottomWidth={"3px"} />
                                <Text fontWeight={"bold"}>
                                    Transcript:{" "}
                                    <Text as={"span"} fontWeight={"normal"} color={modalTextColor}>
                                        {selectedEpisode.transcript}
                                    </Text>
                                </Text>
                            </Stack>
                        </ModalBody>

                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    );
};

export default ListenToEpisodes;
