import {
    Avatar,
    Box,
    Flex,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { IExternalYoutubeSubscriptions, createSubscription } from "../../api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { setAllSubscriptions } from "../../reducers/userReducer";
import youtubePopularShows from "../../data/youtube_popular_shows.json";

type Props = {
    isOpen: boolean;
    onClose: any;
};

const AddPopularYouTubeChannelsModal = ({ isOpen, onClose }: Props) => {
    const allSubscriptions = useSelector((state: RootState) => state.user.allSubscriptions || []);
    const email = useSelector((state: RootState) => state.user.userProfile?.user.email || "");

    const [searchTerm, setSearchTerm] = useState("");
    const [filterOptions, setFilterOptions] = useState<IExternalYoutubeSubscriptions[]>(() => youtubePopularShows);

    const [isLoadingId, setIsLoadingId] = useState("");
    const [apiError, setApiError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchTerm.length > 0) {
                const lowerCaseST = searchTerm.toLowerCase();
                const filterData = youtubePopularShows.filter((item) =>
                    item.author.toLowerCase().includes(lowerCaseST),
                );
                setFilterOptions(filterData);
            } else if (youtubePopularShows.length > 0) {
                setFilterOptions(youtubePopularShows);
            }
        }, 1000);
        return () => clearTimeout(getData);
    }, [searchTerm]);

    const isAlreadyPresent = (channelId: string) => {
        return allSubscriptions.filter((item) => item?.additional_info?.channel_id === channelId).length > 0;
    };

    const onAddChannel = async (channelDetails: IExternalYoutubeSubscriptions) => {
        setApiError("");
        setIsLoadingId(channelDetails.channel_id);
        try {
            const response = await createSubscription({
                email,
                platform: "YOUTUBE",
                url: channelDetails.url,
            });
            dispatch(setAllSubscriptions([...allSubscriptions, response]));
        } catch (error) {
            setApiError("Something went wrong while adding subscription please try again.");
        }
        setIsLoadingId("");
    };

    const borderColor = useColorModeValue("gray.200", "gray.600");
    const linkColor = useColorModeValue("blue.500", "blue.300");
    const errorColor = useColorModeValue("red.500", "red.300");
    const iconButtonBg = useColorModeValue("secondary.900", "secondary.700");
    const iconButtonHoverBg = useColorModeValue("blue.400", "blue.500");
    const blackWhiteColor = useColorModeValue("black", "white");
    const backgroundWhiteColor = useColorModeValue("white", "darkPrimary.100");

    // Get unique categories
    const uniqueCategories = Array.from(new Set(youtubePopularShows.map((item) => item.category)));

    // Group items by category
    const groupedItems = uniqueCategories.map((category) => ({
        category,
        items: filterOptions.filter((item) => item.category === category),
    }));

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} scrollBehavior={"inside"}>
            <ModalOverlay />
            <ModalContent style={{ overflow: "auto" }} bg={backgroundWhiteColor}>
                <ModalHeader>
                    <Text>Add Popular YouTube Channels</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack>
                        {!!apiError && (
                            <Stack>
                                <Text color={errorColor}>{apiError}</Text>
                            </Stack>
                        )}
                        {groupedItems.map((group) => (
                            <Box key={group.category}>
                                <Text fontSize="xl" fontWeight="bold" mb={2}>
                                    {group.category}
                                </Text>
                                {group.items.map((item) => (
                                    <Flex
                                        key={item.channel_id}
                                        border={"1px"}
                                        borderStyle={"solid"}
                                        borderColor={borderColor}
                                        borderRadius={"md"}
                                        padding={2}
                                        align={"center"}
                                        justify={"space-between"}
                                        mb={3}
                                    >
                                        <HStack>
                                            <Avatar
                                                name="channel_pic"
                                                src={item.thumbnail_url_default}
                                                borderRadius={"none"}
                                            />
                                            <Link href={item.url} isExternal color={linkColor}>
                                                {item.author}
                                            </Link>
                                        </HStack>
                                        <Stack>
                                            {isAlreadyPresent(item.channel_id) ? (
                                                <Box mr={2}>
                                                    <CheckIcon color={"green.400"} />
                                                </Box>
                                            ) : (
                                                <IconButton
                                                    size={"sm"}
                                                    bg={iconButtonBg}
                                                    _hover={{
                                                        backgroundColor: iconButtonHoverBg,
                                                    }}
                                                    aria-label="add channel"
                                                    icon={<AddIcon />}
                                                    color={blackWhiteColor}
                                                    onClick={() => {
                                                        onAddChannel(item);
                                                    }}
                                                    isLoading={isLoadingId === item.channel_id}
                                                    borderColor={borderColor}
                                                />
                                            )}
                                        </Stack>
                                    </Flex>
                                ))}
                            </Box>
                        ))}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddPopularYouTubeChannelsModal;
