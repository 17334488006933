import { ArrowForwardIcon, CheckIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Button,
    FormControl,
    FormErrorMessage,
    Input,
    Stack,
    Text,
    Link,
    Box,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Progress,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { createPodNon } from "../../api";

type FormState = {
    urls: string;
};

const CreatePodFormHome = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [singleMP3, setSingleMP3] = useState("");
    const [apiErrorMessage, setAPIErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [processingText, setProcessingText] = useState("Processing");
    const rotatingTexts = [
        "Robots hard at work.",
        "Configurating stuff.",
        "Ones and zeros being generated.",
        "AI doing AI things.",
        "Pushing data through the tubes.",
    ];
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormState>();

    const onHandleSubmit = async (data: FormState) => {
        setAPIErrorMessage("");
        setIsLoading(true);
        setShowModal(true);
        try {
            const response = await createPodNon(data.urls);
            setSingleMP3(response);
            reset();
        } catch (error) {
            setAPIErrorMessage("Something went wrong while generating the podcast.");
        }
        setIsLoading(false);
        setShowModal(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * rotatingTexts.length);
            setProcessingText(rotatingTexts[randomIndex]);
        }, 5000); // Change text every 5 seconds

        return () => clearInterval(interval);
    }, []);

    const primaryBg = useColorModeValue("lightPrimary.100", "darkPrimary.100");
    const primaryBorder = useColorModeValue("lightPrimary.400", "darkPrimary.400");
    const primaryText = useColorModeValue("lightPrimary.800", "darkPrimary.800");
    const errorColor = useColorModeValue("red.500", "red.300");
    const inputBg = useColorModeValue("lightPrimary.100", "darkPrimary.100");
    const boxBg = useColorModeValue("lightPrimary.50", "darkPrimary.50");
    const textColor = useColorModeValue("gray.500", "gray.300");

    return (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Stack flex={1} w={"full"} spacing={5}>
                <Box
                    borderStyle={"solid"}
                    borderWidth={"0px"}
                    borderColor={primaryBorder}
                    p={4}
                    borderRadius={"lg"}
                    boxShadow={"lg"}
                    bgColor={primaryBg}
                >
                    <Stack
                        backgroundColor={boxBg}
                        height={"full"}
                        p={8}
                        spacing={5}
                        borderRadius={"lg"}
                        boxShadow={"lg"}
                    >
                        <Stack>
                            <Text fontSize={"2xl"} fontWeight={"bold"} color={primaryText}>
                                {`No account? Try it out!`}
                            </Text>
                            <Text color={textColor} fontSize={"m"}>
                                - Convert a YouTube video into a summarized podcast.
                                <br />- Need help? Check out our{" "}
                                <a
                                    href="https://docs.mydailypod.com/on-demand-pod/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <strong>docs</strong>
                                </a>
                                .
                            </Text>
                        </Stack>
                        <FormControl isInvalid={errors.urls ? true : false}>
                            <Input
                                type="text"
                                bgColor={inputBg}
                                placeholder={"Enter YouTube Video URL here"}
                                // placeholder="https://www.youtube.com/watch?v=Lm3Bntsp2ck "
                                {...register("urls", {
                                    required: "This is required",
                                    pattern: {
                                        value: /^(https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[^\s]*)?)$/,
                                        message: "Please enter a valid URL.",
                                    },
                                })}
                            />
                            {!!errors.urls && <FormErrorMessage>{errors.urls.message}</FormErrorMessage>}
                        </FormControl>

                        {!!singleMP3 && (
                            <Stack>
                                <Text fontSize={"sm"}>Generated Podcast</Text>
                                <audio style={{ width: "100%" }} controls src={singleMP3} />
                            </Stack>
                        )}

                        {!!apiErrorMessage && (
                            <Stack>
                                <Text color={errorColor} fontSize={"sm"}>
                                    {apiErrorMessage}
                                </Text>
                            </Stack>
                        )}

                        <Button
                            width={{ base: "100%", md: "40%" }}
                            rightIcon={<ArrowForwardIcon />}
                            fontSize={{ base: "sm", md: "inherit" }}
                            variant={"primary"}
                            type="submit"
                            isLoading={isLoading}
                            isDisabled={isLoading}
                        >
                            Generate Pod
                        </Button>
                    </Stack>
                </Box>

                {isLoading && (
                    <Alert status="info" alignItems={"flex-start"}>
                        <AlertIcon />
                        <Text>
                            {`Hang tight! Your custom pod is being created and will be ready shortly.`}
                            <br /> <br />
                            {`Create an account, and get your favorite YouTube channels summarized every day!`}
                        </Text>
                    </Alert>
                )}

                {/* <Modal isOpen={showModal} onClose={() => setShowModal(false)} size={"2xl"} scrollBehavior={"inside"}>
                    <ModalOverlay />
                    <ModalContent style={{ overflow: "auto" }}>
                        <ModalHeader>Generating Podcast</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box textAlign="center">
                                <Text>{processingText}</Text>
                            </Box>
                            <Progress size="lg" isIndeterminate hasStripe={true} />
                        </ModalBody>
                        <ModalFooter>
                            <Alert status="info" alignItems={"flex-start"}>
                                <Text color={primaryText}>
                                    {`Hang tight! Your custom pod is being created and will be ready shortly.`}
                                    <br /> <br />
                                    {`Create an account, and get your favorite YouTube channels summarized every day!`}
                                </Text>
                            </Alert>
                        </ModalFooter>
                    </ModalContent>
                </Modal> */}
            </Stack>
        </form>
    );
};

export default CreatePodFormHome;
