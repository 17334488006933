import { useQuery } from "react-query";
import { getAllHost, updateUserPodConfig } from "../../api";
import { Avatar, Divider, Select, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import Loading from "../Loading/Loading";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setHostName } from "../../reducers/userReducer";

const AllPodcastHost = () => {
    const {
        data: allHosts,
        error: hostError,
        isLoading: isLoadingHost,
        refetch: refetchHosts,
    } = useQuery(["getAllHost"], () => getAllHost(), {
        enabled: false, // Disable automatic fetching on component mount
    });
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState("");
    const dispatch = useDispatch();

    const userProfile = useSelector((state: RootState) => state.user.userProfile);
    const email = userProfile?.user?.email || "";
    const selectedHost = userProfile?.user?.user_podcast_configs?.host || "";

    useEffect(() => {
        refetchHosts();
    }, []);

    const onSelectNewHost = async (name: string) => {
        setApiError("");
        setIsLoading(true);
        try {
            await updateUserPodConfig(email, name);
            dispatch(setHostName({ name }));
        } catch (error) {
            console.log({ error });
            setApiError("Failed to update configurations");
        }
        setIsLoading(false);
    };

    const selectedHostDetails = allHosts?.hosts.filter((item) => item.name === selectedHost);

    const primaryTextColor = useColorModeValue("lightPrimary.900", "darkPrimary.200");
    const errorColor = useColorModeValue("red.500", "red.300");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const bgColor = useColorModeValue("lightPrimary.50", "darkPrimary.50");
    const descriptionTextColor = useColorModeValue("gray.500", "gray.300");

    return (
        <Stack spacing={2}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
                {"Select My Podcast Host"}
            </Text>
            <Text fontSize={"md"}>This is your current Podcast Host. Select your favorite!</Text>

            {!!hostError && <Text color={errorColor}>{"Something went wrong while fetching all podcast hosts"}</Text>}

            {isLoadingHost ? (
                <Loading />
            ) : (
                <>
                    <div>
                        <Select
                            placeholder="Select option"
                            value={selectedHost}
                            bgColor={bgColor}
                            onChange={(event) => {
                                onSelectNewHost(event.target.value);
                            }}
                            isDisabled={isLoading}
                        >
                            {allHosts?.hosts?.map((item) => (
                                <option key={item._id} value={item.name}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </div>

                    {!!apiError && (
                        <Text color={errorColor} mt={4}>
                            {apiError}
                        </Text>
                    )}

                    <Divider borderBottomWidth={"2px"} mt={4} borderColor={borderColor} />
                    <Stack pt={4} pb={4}>
                        <Text fontWeight={"normal"} color={descriptionTextColor} textAlign={"justify"}>
                            <Avatar
                                src={selectedHostDetails?.[0]?.image_url}
                                size={"2xl"}
                                borderRadius={"md"}
                                float={"left"}
                                marginRight={2}
                            />
                            <Text>
                                <Text as={"span"} fontWeight={"bold"}>{`${selectedHostDetails?.[0]?.name}`}</Text>
                            </Text>
                            {selectedHostDetails?.[0]?.description}
                        </Text>
                    </Stack>
                </>
            )}
        </Stack>
    );
};

export default AllPodcastHost;
