import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const splitTitle = (title: string) => {
    // Strip out quotation marks
    const strippedTitle = title.replace(/^["']|["']$/g, "").replace(/^["']|["']$/g, "");
    const [firstPart, ...rest] = strippedTitle.split(":");
    return [firstPart, rest.join(":")];
};

export const formatDate = (dateString: string) => {
    const parsedDate = dayjs(dateString).utc().format();
    const formattedDate = dayjs(parsedDate).tz("America/New_York").format("MMMM DD");
    return formattedDate;
};
