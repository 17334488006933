const colors = {
    black: "#0e1012",
    // primaryBg: "#",
    primaryLightBg: "#FFFFFFA8",
    primaryGradientBg: "linear-gradient(114.36deg, #F1F7FF -13.09%, #C2DCFF 145.26%)",
    borderMercury: "#E9E9E9",
    darkBackgroundOnTop: "#3245634a",
    cardGradientBg: "linear-gradient(90deg, rgba(255, 247, 248, 0.77) 0%, rgba(0, 132, 255, 0.77) 100%)",
    lightPrimary: {
        "50": "#EEF7FF", // Background
        "100": "#dde2e6", // Accent
        "200": "#b8c2cc", // Primary
        "300": "#7f8a97", // Primary Text
        "400": "#5a6676", // Primary Hover
        "500": "#0084FF", // Primary Active
        "600": "#0084FD", // Secondary
        "700": "#18222d", // Secondary Hover
        "800": "#0f161f", // Secondary Active
        "900": "#000000", // Text
    },
    darkPrimary: {
        "50": "#1a202c", // Background
        "100": "#2d3748", // Accent
        "200": "#4a5568", // Primary
        "300": "#cbd5e0", // Primary Text
        "400": "#718096", // Primary Hover
        "500": "#0084FF", // Primary Active
        "600": "#718096", // Secondary
        "700": "#cbd5e0", // Secondary Hover
        "800": "#e2e8f0", // Secondary Active
        "900": "#ffffff", // Text
    },
    accent: {
        light: {
            "50": "#e6f4ea",
            "100": "#cce9d5",
            "200": "#99d3ab",
            "300": "#66bd80",
            "400": "#33a755", // Main accent color
            "500": "#1a8a3a",
            "600": "#14732e",
            "700": "#0e5c22",
            "800": "#084516",
            "900": "#04230b",
        },
        dark: {
            "50": "#e6f4ea",
            "100": "#cce9d5",
            "200": "#99d3ab",
            "300": "#66bd80",
            "400": "#33a755", // Main accent color
            "500": "#1a8a3a",
            "600": "#14732e",
            "700": "#0e5c22",
            "800": "#084516",
            "900": "#04230b",
        },
    },
};

export default colors;
