import { ArrowBackIcon, ArrowForwardIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Link,
    Select,
    Stack,
    Text,
    Textarea,
    useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ISingleSubscription, createSubscription } from "../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type FormState = {
    url: string;
};

type Props = {
    onAddSubscription: (value: ISingleSubscription) => void;
};

const CreateSubscriptionForm = ({ onAddSubscription }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiErrorMessage, setAPIErrorMessage] = useState("");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        watch,
    } = useForm<FormState>();
    const email = useSelector((state: RootState) => state?.user?.userProfile?.user?.email || "");

    const onHandleSubmit = async (data: FormState) => {
        setAPIErrorMessage("");
        setIsLoading(true);
        try {
            const response = await createSubscription({
                email,
                platform: "YOUTUBE",
                url: data.url,
            });
            onAddSubscription(response);
            reset();
        } catch (error) {
            setAPIErrorMessage("Something went wrong while creating the subscription.");
        }
        setIsLoading(false);
    };

    const urlValue = watch("url");

    const inputBg = useColorModeValue("lightPrimary.50", "darkPrimary.50");
    const errorColor = useColorModeValue("red.500", "red.300");

    return (
        <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Box>
                <FormControl isInvalid={errors.url ? true : false}>
                    <Stack spacing={4} mt={0}>
                        <Flex direction={{ base: "column", md: "row" }} alignItems={{ base: "stretch", md: "center" }}>
                            <Textarea
                                bgColor={inputBg}
                                mb={{ base: 2, md: 0 }}
                                flex="1"
                                mr={{ md: 6 }}
                                width="100%"
                                placeholder="https://www.youtube.com/@SaturdayNightLive"
                                {...register("url", {
                                    required: "This is required",
                                    pattern: {
                                        value: /^(https?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[^\s]*)?)$/,
                                        message: "Please enter a valid URL.",
                                    },
                                })}
                                noOfLines={4}
                            />
                            <Button
                                fontSize={{ base: "sm", md: "md" }}
                                variant={"primary"}
                                type="submit"
                                isLoading={isLoading}
                                size="lg"
                                minW={{ base: "100%", md: "auto" }}
                                isDisabled={!urlValue || isLoading}
                                leftIcon={urlValue ? <></> : <ArrowBackIcon />}
                            >
                                {urlValue ? (
                                    <strong>Add Channels</strong>
                                ) : (
                                    <>
                                        <strong>YouTube Url</strong>
                                    </>
                                )}
                            </Button>
                        </Flex>
                        {!!errors.url && <FormErrorMessage>{errors.url.message}</FormErrorMessage>}
                    </Stack>
                </FormControl>

                {!!apiErrorMessage && (
                    <Stack mt={2}>
                        <Text color={errorColor} fontSize={"sm"}>
                            {apiErrorMessage}
                        </Text>
                    </Stack>
                )}
            </Box>
        </form>
    );
};

export default CreateSubscriptionForm;
