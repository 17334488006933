import * as amplitude from '@amplitude/analytics-browser';


const AMPLITUDE_API_KEY = '48b5b20dff8d9f0692be550fb5c6c36a';

amplitude.init(AMPLITUDE_API_KEY, {
    logLevel: amplitude.Types.LogLevel.Warn,
    });

export default amplitude;
