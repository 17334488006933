import { Box, Text, Link, useColorModeValue } from "@chakra-ui/react";
import { FaDiscord, FaEnvelope } from "react-icons/fa";

const ContactUs = () => {
    const linkColor = useColorModeValue("lightPrimary.400", "darkPrimary.400");

    return (
        <Box textAlign={"left"}>
            <Text fontSize="s" pb={2} color={linkColor}>
                <strong>Contact Us!</strong>
            </Text>
            <Box display="flex" flexDirection="row" textAlign="left" gap={2}>
                <Link href="https://discord.gg/yeJAz9z8" isExternal>
                    <FaDiscord size={24} color="#7289da" />
                </Link>
                <Link href="mailto:info@mydailypod.com" isExternal>
                    <FaEnvelope size={24} color="#7289da" />
                </Link>
            </Box>
            <Text fontSize="s" pt={2} color={linkColor}>
                <Link href="https://mydp.features.vote/board" isExternal>
                    Request a Feature
                </Link>
            </Text>
        </Box>
    );
};

export default ContactUs;
