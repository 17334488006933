import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";

import NoPage from "./pages/NoPage";
import CreatePod from "./pages/createPod/CreatePod";
import ManageAccount from "./pages/manageAccount/Account";
import Success from "./pages/manageAccount/Success";
import Welcome from "./pages/welcome/Welcome";
import Loading from "./components/Loading/Loading";
import { Box, ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Provider, useSelector } from "react-redux";
import theme from "./themes/theme";
import { RootState, persistor, store } from "./store/store";
import ListenToEpisodes from "./pages/listenToEpisodes";
import UserPreferencesPage from "./pages/userPreferences";
import MainLayout from "./pages/layout/MainLayout";
import HomePage from "./pages/home";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import initializeAnalytics from "./utils/analytics";
// import mixpanel from "mixpanel-browser";
import amplitude from "./utils/amplitude";
import SharePod from "./pages/sharePod/SharePod";

const queryClient = new QueryClient();
const GOOGLE_CLIENT_ID = import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID as string;

initializeAnalytics();

// Near entry of your product, init Mixpanel
// mixpanel.init('YOUR_TOKEN', {debug: true, track_pageview: true, persistence: 'localStorage'});

const RoutesOutlet = () => {
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const user = useSelector((state: RootState) => state?.user?.userProfile);

    useEffect(() => {
        amplitude.track("App Loaded");
        initUserProfile();
    }, []);

    const initUserProfile = async () => {
        if (!user) {
            if (!location.pathname.includes("/share")) {
                navigate("/", { replace: true });
            }
        } else {
            // Simulating a login and checking if its the first login
            const isFirstLogin = localStorage.getItem("isFirstLogin") === null;
            // After login logic
            if (isFirstLogin) {
                localStorage.setItem("isFirstLogin", "false"); // Set to false after the first login
                navigate("/user-preferences", { replace: true });
            }
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <Box minHeight="100vh" w="100%" bg="primaryBackground" color="primaryText">
                <Loading />
            </Box>
        );
    }

    return user ? (
        <MainLayout>
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="create-pod" element={<CreatePod />} />
                <Route path="user-preferences" element={<UserPreferencesPage />} />
                <Route path="listen-to-episodes" element={<ListenToEpisodes />} />
                <Route path="manage-account" element={<ManageAccount />} />
                <Route path="/share/:id" element={<SharePod isInsideAuth={true} />} />
                <Route path="/manage-account/success" element={<Success />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
        </MainLayout>
    ) : (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/share/:id" element={<SharePod isInsideAuth={false} />} />
        </Routes>
    );
};

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <RoutesOutlet />
            </BrowserRouter>
        </QueryClientProvider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ChakraProvider theme={theme}>
                <ColorModeScript initialColorMode={theme.config.initialColorMode} />
                <Provider store={store}>
                    <PersistGate loading={<Loading />} persistor={persistor}>
                        <App />
                    </PersistGate>
                </Provider>
            </ChakraProvider>
        </GoogleOAuthProvider>
    </React.StrictMode>,
);
