import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { useColorModeValue } from "@chakra-ui/react";

const variantSolid = defineStyle((props) => {
    const { colorScheme: c } = props;

    const color = useColorModeValue(`${c}.500`, `${c}.300`);
    const hoverColor = useColorModeValue(`${c}.600`, `${c}.400`);
    const textColor = useColorModeValue("white", "black");

    return {
        borderWidth: "1px",
        borderColor: color,
        boxShadow: "xs",
        bg: color,
        color: textColor,
        fontWeight: "medium",
        _hover: {
            borderColor: hoverColor,
            bg: hoverColor,
            _disabled: {
                bg: hoverColor,
            },
        },
        _dark: {
            boxShadow: "dark-xs",
        },
        _loading: {
            bg: color,
            opacity: 0.8,
            _spinner: {
                color: textColor,
            },
        },
    };
});

const variantAccent = defineStyle((props) => {
    const { colorScheme: c } = props;

    // const color = useColorModeValue(`${c}.500`, `${c}.300`);
    const color = useColorModeValue(`blue`, `${c}.300`);
    const hoverColor = useColorModeValue(`${c}.600`, `${c}.400`);
    const textColor = useColorModeValue("white", "black");

    return {
        borderWidth: "1px",
        borderColor: color,
        boxShadow: "xs",
        bg: color,
        color: textColor,
        fontWeight: "medium",
        _hover: {
            borderColor: hoverColor,
            bg: hoverColor,
        },
        _dark: {
            boxShadow: "dark-xs",
        },
        _loading: {
            bg: color,
            opacity: 0.8,
            _spinner: {
                color: textColor,
            },
        },
    };
});

const variantOutline = defineStyle((props) => {
    const { colorScheme: c } = props;

    const color = useColorModeValue(`${c}.500`, `${c}.300`);
    const hoverColor = useColorModeValue(`${c}.400`, `${c}.200`);

    return {
        fontWeight: "medium",
        color: color,
        _hover: {
            color: hoverColor,
            bg: useColorModeValue("blackAlpha.50", "whiteAlpha.50"),
        },
        _dark: {
            color: color,
            _hover: {
                color: hoverColor,
                bg: "whiteAlpha.50",
            },
        },
    };
});

const variantGhost = defineStyle((props) => {
    return {
        fontWeight: "medium",
        _checked: {
            bg: useColorModeValue("blackAlpha.100", "whiteAlpha.100"),
        },
        _dark: {
            _checked: {
                bg: "whiteAlpha.100",
            },
        },
    };
});

const variantPrimary = defineStyle((props) => {
    return variantSolid({ ...props, colorScheme: "lightPrimary" });
});

const sizes = {
    lg: defineStyle({
        h: "8",
        minW: "10",
        fontSize: "md",
        px: "4",
        rounded: "6",
    }),
    md: defineStyle({
        h: "7",
        minW: "8",
        fontSize: "sm",
        px: "3",
        rounded: "4",
    }),
    sm: defineStyle({
        h: "6",
        minW: "6",
        fontSize: "sm",
        px: "2",
        rounded: "3",
    }),
    xs: defineStyle({
        h: "5",
        minW: "4",
        fontSize: "xs",
        px: "1",
        rounded: "3",
    }),
};

export default defineStyleConfig({
    defaultProps: {
        size: "md",
        variant: "primary",
    },
    variants: {
        solid: variantSolid,
        outline: variantOutline,
        ghost: variantGhost,
        primary: variantPrimary,
        accent: variantAccent,
        // secondary: variantSecondary,
        // tertiary: variantTertiary,
    },
    sizes,
});
