import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ILoginResponse, ISingleSubscription } from "../api/models";

type GoogleUserResponse = {
    code: string;
    scope: string;
    authuser: string;
    prompt: string;
};

export interface UserState {
    googleUserResponse: GoogleUserResponse | undefined;
    userProfile: ILoginResponse | undefined;
    allSubscriptions: ISingleSubscription[];
    episodeCount: number;
}

const initialState: UserState = {
    googleUserResponse: undefined,
    userProfile: undefined,
    allSubscriptions: [],
    episodeCount: 0,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setGoogleUserResponse: (state, action: PayloadAction<GoogleUserResponse | undefined>) => {
            state.googleUserResponse = action.payload;
        },
        setUserProfile: (state, action: PayloadAction<ILoginResponse | undefined>) => {
            console.log("SETTING USER PROFILE: ", action.payload);
            state.userProfile = action.payload;
        },
        setEpisodeCount: (state, action: PayloadAction<ILoginResponse | undefined>) => {
            console.log("SETTING EPISODE COUNT: ", action.payload?.episode_count);
            if (action.payload) state.episodeCount = action.payload.episode_count;
        },
        setAllSubscriptions: (state, action: PayloadAction<ISingleSubscription[]>) => {
            state.allSubscriptions = action.payload;
        },
        setHostName: (state, action: PayloadAction<{ name: string }>) => {
            if (state?.userProfile) {
                state.userProfile["user"]["user_podcast_configs"]["host"] = action.payload.name;
            }
        },
    },
});

export const { setAllSubscriptions, setEpisodeCount, setGoogleUserResponse, setUserProfile, setHostName } =
    userSlice.actions;

export const getGoogleUserResponse = (state: RootState) => state.user.googleUserResponse;
export const getUserProfile = (state: RootState) => state.user.userProfile;

export default userSlice.reducer;
