import { useState, useEffect } from "react";
import { Button, Stack, Text, Image } from "@chakra-ui/react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { loginWithGoogleToken } from "../../api";
import { setGoogleUserResponse, setUserProfile, setEpisodeCount } from "../../reducers/userReducer";
import { useGoogleLogin } from "@react-oauth/google";
import LogRocket from "logrocket";
// import mixpanel from "mixpanel-browser";
import amplitude from "../../utils/amplitude";




const logrocket_env = "production";

// Initialize LogRocket only in production
if (import.meta.env.VITE_APP_ENVIRONMENT === logrocket_env) {
    // console.log("Initializing LogRocket...");
    LogRocket.init("a89poe/my-daily-pod");
} else {
    // console.log("LogRocket not initialized, environment:", import.meta.env.VITE_APP_ENVIRONMENT);
}

interface LoginPageProps {
    isMainHomePage?: boolean;
}



const LoginPage = ({ isMainHomePage }: LoginPageProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState("");
    const dispatch = useAppDispatch();

    // Capture when users land on the main page
    useEffect(() => {
        if (isMainHomePage && import.meta.env.VITE_APP_ENVIRONMENT === logrocket_env) {
            console.log("Tracking MainPageVisit event");
            LogRocket.track("MainPageVisit");
            amplitude.track("MainPageVisit");

        }
    }, [isMainHomePage]);


    

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setApiError("");
            try {
                setGoogleUserResponse(tokenResponse as unknown as any);
                const response = await loginWithGoogleToken(tokenResponse.code);
                dispatch(setUserProfile(response));
                dispatch(setEpisodeCount(response));

                amplitude.setUserId(response?.user?.email);

                // Identify the user in LogRocket after successful login
                if (import.meta.env.VITE_APP_ENVIRONMENT === logrocket_env) {
                    console.log("Identifying user in LogRocket:", response?.user?.email);
                    LogRocket.identify(response?.user?.email || "", {
                        email: response?.user?.email || "",
                    });
                }
            } catch (error) {
                setApiError("Something went wrong while logging in, please try again.");
            }
            setIsLoading(false);
        },
        flow: "auth-code",
        scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
        // scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly",
    });

    return isMainHomePage ? (
        <Stack>
            <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={5}
                align={"center"}
                alignSelf={"center"}
                position={"relative"}
                width={"full"}
            >
                <Button
                    onClick={() => {
                        setIsLoading(true);
                        login();
                    }}
                    width={"100%"}
                    height={"auto"}
                    variant={"unstyled"}
                    isLoading={isLoading}
                    p={0}
                    m={0}
                >
                    <Image
                        src="/google-continue-white.svg"
                        alt="Login with Google"
                     
                        
                        // boxSize={{ base: "200px", lg: "300px" }}
                    />
                </Button>
            </Stack>
            {!!apiError && <Text color={"red.500"}>{apiError}</Text>}
        </Stack>
    ) : null;
};

export default LoginPage;
