import React from "react";
import { Box } from "@chakra-ui/react";
import loading from "../../assets/loading.svg";

const Loading = () => (
    <Box display="flex" alignItems="center" justifyContent="center">
        <img src={loading} alt="Loading" />
    </Box>
);

export default Loading;
